<template>
  <arora-nuxt-link
    class-name="v-group-henneth-annun"
    :href="group.Link"
    :label="sanitize(group.Name)"
  >
    <div
      v-if="isSmall && imageExists(group.ImageForMobile)"
      class="v-group-henneth-annun--image"
    >
      <arora-nuxt-image
        :alt="sanitize(group.Name)"
        :image="group.ImageForMobile"
      />
    </div>
    <div
      v-else-if="imageExists(group.Image)"
      class="v-group-henneth-annun--image"
    >
      <arora-nuxt-image
        :alt="group.Name"
        :image="group.Image"
      />
    </div>
    <div
      v-else
      class="v-group-henneth-annun--image v-group-henneth-annun--image__placeholder"
    >
      <icon-menu-placeholder />
    </div>
    <div
      class="v-group-henneth-annun--title"
      v-html="sanitize(group.Name)"
    />
  </arora-nuxt-link>
</template>

<script setup lang="ts">
import type { Group } from '~types/menuStore'

import { useWindowSize } from '@arora/common'

defineProps<{
  group: Group
}>()

const { sanitize } = useI18nSanitized()
const { isSmall } = useWindowSize()
const { imageExists } = useImageInfo()
</script>

<style lang="scss">
@use 'assets/variables';
@use 'assets/mixins';

.v-group-henneth-annun-card {
  @include mixins.trans;

  box-shadow: none;
  background: none;

  &:hover {
    opacity: 0.5;
    scale: 1.1;
  }

  .v-group-henneth-annun {
    width: 100%;

    &--image {
      height: fit-content;
      margin-bottom: 0.75rem;

      &__placeholder {
        display: flex;
        justify-content: center;
        align-groups: center;

        height: calc(100% - 2.75rem);

        background: variables.$BodyTextColorLight;
        color: variables.$BodyTextColor;
        border-radius: variables.$BorderRadius;
        opacity: 0.5;
        width: 100%;

        svg {
          width: 160px;
          height: auto;
        }
      }

      .v-img-fluid {
        height: auto;
        width: 100%;
        border-radius: variables.$BorderRadius;
      }
    }

    &--title {
      color: variables.$BodyTextColor;
      font-size: variables.$TextSizeH3;
      font-weight: 600;
    }
  }
}
</style>
