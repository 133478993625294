import type { HasActive, HasSortWeight, StoreState } from '~types/common'
import type { CitySelect } from '~types/settingsVariants'

import type { GUID, HasID } from '@arora/common'

import type { ComputedRef, Ref, UnwrapRef } from 'vue'

export type CityOrganisation = HasID &
  HasActive & {
    //не используемые DeliverySum и DeliverySumPaid высчитываются в PriceRule
    DeliverySum: number
    DeliverySumPaid: number
    DistrictsPrices: DistrictsPrice[]
    OrganizationID: GUID
    OwnerID: string
    PaidDeliveryOptionID: GUID
    PriceRules: never[]
    ProviderID: GUID
    RestaurantID: GUID
    Title: string
    TitleForGeocoding: string
    Weight: number
  }

export type DistrictsPrice = HasID & {
  Active: boolean
  CityID: string
  ClearLocalityInComponent: boolean
  ClearStreetInComponent: boolean
  ClearDistrictIfThereIsAStreet: boolean
  ClearLocalityIfThereIsAStreet: boolean
  OnlyLastDistrict: boolean
  //не используемые DeliverySum и DeliverySumPaid высчитываются в PriceRule
  DeliverySum: number
  DeliverySumPaid: number
  Departments: Department[]
  HideIfTerminalIsInactive: boolean
  OffReason: string
  OrganizationID: GUID
  OwnerID: GUID
  PaidDeliveryOptionID: GUID
  PolygonConfiguration: PolygonConfiguration
  PriceRules: PriceRule[]
  RestaurantID: GUID
  Title: string
  TitleForGeocoding: string
  Weight: number
}

export type PriceRule = HasID & {
  CityID: GUID
  DistrictID: GUID
  PaidDeliveryOptionID: GUID
  SumFrom: number
  DeliveryPrice: number
}

type PolygonConfiguration = {
  Coordinates: number[][][]
  FillColor: string
  FillColorOnSelected: string
  StrokeColor: string
}

export type Department = HasID & {
  Active: boolean
  Name: string
  OrganizationID: GUID
  OwnerID: GUID
  ProviderID: GUID
  RestaurantID: GUID
  Terminals: Terminal[]
  Weight: number
}

export type Terminal = HasID & {
  Active: boolean
  Address: string
  CanOrderSelfService: boolean
  City: string
  DeliverySetting: OrderTypeSetting
  DepartmentID: GUID
  Latitude: number
  Longitude: number
  Name: string
  PayTypeSort: PayTypeSort[]
  InHallSetting: OrderTypeSetting
  SelfServiceSetting: OrderTypeSetting
  Weight: number
  TemporarilyUnavailableForSelfService: boolean
  TemporarilyUnavailableForDelivery: boolean
  IsTemporarilyNonActive: boolean
  NotWorkingOnScheduleNow: boolean
  HideInVacancySelect: boolean
}

export type TerminalUnavailableMessage = {
  enableTerminal: boolean
  unavailableMessage: string
}

export interface IAllTerminalsUnavailable {
  allTerminalsDisabled: boolean
  message: string
}

export class AllTerminalsUnavailable implements IAllTerminalsUnavailable {
  allTerminalsDisabled: boolean

  message: string

  constructor(parsed: IAllTerminalsUnavailable) {
    this.allTerminalsDisabled = parsed.allTerminalsDisabled
    this.message = parsed.message
  }

  shouldShowMessage(): boolean {
    return this.allTerminalsDisabled && this.message.length > 0
  }
}

export type OrderTypeSetting = {
  ApplePayEnabled: boolean
  AsSoonAsPosibleEnabled: boolean
  BankCardPayEnabled: boolean
  CashPayEnabled: boolean
  DaysCanOrder: number
  DeliveryToTimeEnabled: boolean
  GooglePayEnabled: boolean
  MaxDefferedOrderTimeInterval: number
  MinDefferedOrderTimeInterval: number
  OnlinePayEnabled: boolean
  QRPayEnabled: boolean
  HallTablesNumber: number
}

export type PayTypeSort = HasSortWeight & {
  Type: number
}

export type DeliveryAndPay = {
  DeliveryAreas: DeliveryArea[]
  EnableCashCourier: boolean
  EnableDeliveryCourier: boolean
  EnablePayCardCourier: boolean
  EnablePayCardOnline: boolean
  EnableSelfService: boolean
  EnableInHall: boolean
  GoogleMapKey: string //in case we will actually use it
  OrganizationID: GUID
}

export type DeliveryArea = HasID &
  HasSortWeight &
  HasActive & {
    CitiesInclude: string
    Color: string
    MinDeliveryPrice: string
    Name: string
    OrganizationID: GUID
    RestaurantID: GUID
  }

export type Restaurant = HasID &
  HasSortWeight &
  HasActive & {
    Alias?: string | null
    Domain?: string | null
    FolderIsEmpty?: boolean
    Name: string
    ShowRestaurantInSelect: boolean
    Type?: number
    ExternalLink?: string
  }

export type Street = {
  data: string
  value: string
}

export type AddressStore = {
  CityOrganisation: Ref<UnwrapRef<StoreState<CityOrganisation[]>>>
  DeliveryAndPay: Ref<UnwrapRef<StoreState<DeliveryAndPay>>>
  Restaurants: Ref<UnwrapRef<StoreState<Restaurant[]>>>
  StreetsList: Ref<UnwrapRef<StoreState<Street[]>>>
  Terminals: Ref<UnwrapRef<StoreState<Terminal[]>>>
  ActiveCities: ComputedRef<CityOrganisation[]>
  DeliveryZones: Ref<UnwrapRef<Map<string, DistrictsPrice>>>

  checkAllTerminals: () => Promise<void>
  checkTerminal: (terminalId: GUID | undefined) => Promise<TerminalUnavailableMessage>
  initCities: () => Promise<void>
  initDeliveryAndPay: () => Promise<void>
  initRestaurant: () => Promise<void>
  initTerminals: () => Promise<void>
  loadStreetList: (departmentId: GUID, input: string, cityId: GUID | undefined) => Promise<void>
  onProductAdd: (noAddress: boolean) => Promise<void>
  showCityPopupAtEntrance: (payload: ShowCityPopupPayload) => Promise<void>
  showPopupAtEntrance: (noAddress: boolean) => Promise<void>
}

export type ShowCityPopupPayload = {
  showPopupOnce: boolean
  listCity: CitySelect
  showCloseButton: boolean
  fromCityPopup: boolean
}

export type terminalTemporarilyNoActive = {
  TemporarilyUnavailableForSelfService: boolean
  TemporarilyUnavailableForDelivery: boolean
  IsTemporarilyNonActive: boolean
  IsTerminalNoActive: boolean
}

export type districtDeliveryParameters = {
  IsPriceRulesOn: boolean
  PriceRules: PriceRule[]
  DeliverySum: number
  DeliverySumPaid: number
}

export type SavedAddressInternal = {
  departmentId?: GUID
  terminalId?: GUID
  district?: string
  districtId?: GUID
  city?: string
  cityId?: GUID
  Street?: string
  House?: string
  Apartment?: string
  Corpus?: string
  Building?: string
  Doorway?: string
  Intercom?: string
  Floor?: string
  comment?: string
  OrderDeliveryType?: number
}
