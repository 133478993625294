<template>
  <template v-if="appConfig.VueSettingsPreRun.SmallBasketCounterHidden">
    <slot />
  </template>
  <common-badge
    v-else
    show-when-zero
    :content="totalAmount"
  >
    <slot />
  </common-badge>
</template>

<script setup lang="ts">
import type { CartItem } from '~types/clientStore'

import { PositionType } from '~api/consts'

const clientStore = useClientStore()

onMounted(() => {
  clientStore.initClientState()
})

const appConfig = useAppConfig()
const content = computed<CartItem[]>(() => {
  const result: CartItem[] | undefined = clientStore.ClientState?.data?.Cart?.Content

  if (!result) return []

  return result.filter((ci: CartItem) => ci.PositionType !== PositionType.Additional)
})
const totalAmount = computed<number>(() => {
  if (content.value.length === 0) return 0

  return content.value
    .map((m) => m.Count)
    .reduce((item1, item2) => {
      return item1 + item2
    })
})
</script>
