<template>
  <template v-if="isLoggedIn">
    <div
      v-if="appConfig.RestaurantSettingsPreRun.BonusSystemEnabled"
      :class="[isSmall ? 'v-mr-xs' : 'v-mr-md']"
    >
      <arora-button
        class-name="v-btn v-btn-link v-btn-text v-no-underline"
        ignore-settings
        :label="translate('loginButtonPage.points')"
        @click="pointsButtonClick"
      >
        <div class="v-d-flex v-flex-column v-align-items-center">
          <div class="v-login-button-icon__umbar v-login-button-icon__umbar--coins">
            <icon-general-coins />
          </div>
          <account-points-balance
            v-if="PointsBalance >= 0"
            balance-only
          />
          <span
            v-else
            class="v-login-button-text"
            v-html="translate('loginButtonPage.points')"
          />
        </div>
      </arora-button>
    </div>

    <arora-button
      class-name="v-btn v-btn-link v-btn-text v-no-underline"
      ignore-settings
      :label="translate('personalCabinetPage.personalCabinet')"
      @click="personalCabinetClick"
    >
      <div class="v-d-flex v-flex-column v-align-items-center">
        <div class="v-login-button-icon__umbar v-login-button-icon__umbar--user">
          <icon-account-user />
        </div>

        <span
          v-if="
            appConfig.VueSettingsPreRun.LoginShowUserNameInButton &&
            !stringIsNullOrWhitespace(userNameShort)
          "
          class="v-login-button-text"
          v-html="clean(userNameShort)"
        />
        <span
          v-else
          class="v-login-button-text"
          v-html="translate('loginButtonPage.profile')"
        />
      </div>
    </arora-button>
  </template>

  <template v-else>
    <div
      v-if="appConfig.RestaurantSettingsPreRun.BonusSystemEnabled"
      :class="[isSmall ? 'v-mr-xs' : 'v-mr-md']"
    >
      <arora-button
        class-name="v-btn v-btn-link v-btn-text v-no-underline"
        ignore-settings
        :label="translate('loginButtonPage.points')"
        @click="pointsButtonClick"
      >
        <div class="v-d-flex v-flex-column v-align-items-center">
          <div class="v-login-button-icon__umbar v-login-button-icon__umbar--coins">
            <icon-general-coins />
          </div>
          <span
            class="v-login-button-text"
            v-html="translate('loginButtonPage.points')"
          />
        </div>
      </arora-button>
    </div>

    <arora-button
      class-name="v-btn v-btn-link v-btn-text v-no-underline"
      ignore-settings
      :label="translate('loginButtonPage.login')"
      @click="personalCabinetClick"
    >
      <div class="v-d-flex v-flex-column v-align-items-center">
        <div class="v-login-button-icon__umbar v-login-button-icon__umbar--login">
          <icon-account-login />
        </div>
        <span
          class="v-login-button-text"
          v-html="translate('loginButtonPage.login')"
        />
      </div>
    </arora-button>
  </template>
</template>

<script setup lang="ts">
import { useCommon, useWindowSize } from '@arora/common'

import { storeToRefs } from 'pinia'

const { showPopup } = defineProps<{
  showPopup: () => Promise<void>
}>()
const accountStore = useAccountStore()

const appConfig = useAppConfig()
const { isLoggedIn, PointsBalance } = storeToRefs(accountStore)

const { stringIsNullOrWhitespace } = useCommon()
const { clean, translate } = useI18nSanitized()
const { isSmall } = useWindowSize()
const { userNameShort } = useAccount()

function pointsButtonClick(): void {
  navigateTo(
    appConfig.VueSettingsPreRun.HeaderRedirectToPointsLink
      ? appConfig.VueSettingsPreRun.Links.PointsLink
      : null
  )
}

function personalCabinetClick(): void {
  if (isLoggedIn.value) {
    navigateTo(appConfig.VueSettingsPreRun.Links.PersonalCabinetLink)
  } else {
    showPopup()
  }
}
</script>

<style lang="scss">
@use 'assets/variables';
@use 'assets/mixins';

.v-login-button-text {
  white-space: nowrap;
  color: variables.$HeaderColor;
}

.v-logout-link {
  color: inherit;
  cursor: pointer;
  margin-top: 3px;

  &:hover {
    text-decoration: underline;
  }
}

.v-login-button-icon__umbar {
  @include mixins.trans;

  background: variables.$SecondaryBackgroundColor;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  margin-bottom: 4px;
  will-change: opacity;
  @include mixins.trans;

  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    opacity: 0.75;
    background: variables.$SecondaryBackgroundColorDarken;
  }

  &--coins {
    .v-currency-wrapper {
      span {
        font-weight: 600;
      }
    }
    svg > path {
      fill: variables.$SecondaryColor;
    }
  }

  &--user {
    svg > path {
      fill: variables.$SecondaryColor;
      stroke: none;
    }
  }

  &--login {
    svg > path {
      stroke: variables.$SecondaryColor;
    }
  }

  svg {
    width: 24px;
    height: 24px;
  }
}
</style>
