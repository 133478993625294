import { useBackendCompatibleGuid } from '@arora/common'

import bridge from '@vkontakte/vk-bridge'

export default function initVKBridge(): void {
  bridge
    .send('VKWebAppInit')
    .then((data) => {
      const restaurantStore = useRestaurantStore()
      const { generateGUID } = useBackendCompatibleGuid()
      const externalSSID = useCookie('externalSSID')

      if (!externalSSID.value) {
        externalSSID.value = generateGUID()
      }
      restaurantStore.SSID = externalSSID.value
      restaurantStore.Source = 'vk-bridge'

      if (data.result) {
        //TODO: possible work with data
      }

      return
    })
    .catch((error) => {
      // Ошибка
      console.error('VK bridge error', error)
    })
}
