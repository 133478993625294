<i18n>
ru:
  appsDescription: Чтобы получить больше бонусов и подарков после регистрации
  appsDownload: Скачайте приложение
  articles: Статьи
  cart: Корзина
  copyright: ©
  contacts: Контакты
  customTextBeforeFooter: ''
  homePage: Домашняя страница
  mainMenu: Меню
  openingHoursHall: Круглосуточно
  operatingHoursDelivery: Круглосуточно
  privacy: Правовая Информация
  profile: Профиль
  restaurantDescription: ''
  siteAuthorRights: https://arora.pro
  titlePaymentSystemsLinks: 'Мы принимаем:'
  titleSocialNetworksLinks: 'Мы в соц. сетях:'
  workTime: 'Режим работы:'
ua:
  appsDescription: Щоб отримати більше бонусів та подарунків після реєстрації
  appsDownload: Скачайте програму
  articles: Статті
  cart: Кошик
  copyright: ©
  contacts: Контактна інформація
  customTextBeforeFooter: ''
  homePage: Домашня сторінка
  mainMenu: Меню
  openingHoursHall: Цілодобово
  operatingHoursDelivery: Цілодобово
  privacy: Правова Інформація
  profile: Профіль
  restaurantDescription: ''
  siteAuthorRights: https://arora.pro
  titlePaymentSystemsLinks: 'Ми приймаємо:'
  titleSocialNetworksLinks: 'Ми в соц. мережах:'
  workTime: 'Режим роботи:'
us:
  appsDescription: To get more bonuses and gifts after registration
  appsDownload: Download the app
  articles: Articles
  cart: Cart
  copyright: ©
  contacts: Contact information
  customTextBeforeFooter: ''
  homePage: Home page
  mainMenu: Menu
  openingHoursHall: Around the clock
  operatingHoursDelivery: Around the clock
  privacy: Legal Information
  profile: Profile
  restaurantDescription: ''
  siteAuthorRights: https://arora.pro
  titlePaymentSystemsLinks: 'We accept:'
  titleSocialNetworksLinks: 'We are in social. networks:'
  workTime: 'Working mode:'
</i18n>

<template>
  <lazy-footer-trollshaws
    v-if="appConfig.VueSettingsPreRun.FooterLayout === 'Trollshaws'"
    :copyright-years="copyrightYears"
    :external-links-footer="externalLinksFooter"
    :link-site="linkSite"
  />
  <lazy-footer-farthing-stone
    v-else-if="appConfig.VueSettingsPreRun.FooterLayout === 'FarthingStone'"
    :copyright-years="copyrightYears"
    :external-links-footer="externalLinksFooter"
    :link-site="linkSite"
  />
  <lazy-footer-southfarthing
    v-else-if="appConfig.VueSettingsPreRun.FooterLayout === 'Southfarthing'"
    :copyright-years="copyrightYears"
    :external-links-footer="externalLinksFooter"
    :link-site="linkSite"
  />
  <div
    v-if="isExtraSmall"
    class="v-mobile-navigation"
  >
    <div class="v-container">
      <div class="v-row">
        <div class="v-col-3">
          <arora-nuxt-link
            class-name="v-main-menu-link"
            :class="{ 'v-active': !Guid.IsNullOrEmpty(menuStore.CurrentGroupId) }"
            :href="appConfig.VueSettingsPreRun.Links.DefaultMenuGroup"
            :label="translate('footerPage.mainMenu')"
          >
            <div class="v-bar--icon">
              <icon-menu-plate />
            </div>
            <div class="v-bar--text">
              <span v-html="translate('footerPage.mainMenu')" />
            </div>
          </arora-nuxt-link>
        </div>
        <div class="v-col-3">
          <arora-nuxt-link
            class-name="v-main-menu-link"
            :href="appConfig.VueSettingsPreRun.Links.ContactsLink"
            :label="translate('footerPage.contacts')"
            active-class="v-active"
          >
            <div class="v-bar--icon">
              <icon-address-mark-light />
            </div>
            <div class="v-bar--text">
              <span v-html="translate('footerPage.contacts')" />
            </div>
          </arora-nuxt-link>
        </div>
        <div class="v-col-3">
          <arora-nuxt-link
            class-name="v-main-menu-link"
            :href="appConfig.VueSettingsPreRun.Links.PersonalCabinetLink"
            :label="translate('footerPage.profile')"
            active-class="v-active"
          >
            <div class="v-bar--icon">
              <icon-account-user />
            </div>
            <div class="v-bar--text">
              <span v-html="translate('footerPage.profile')" />
            </div>
          </arora-nuxt-link>
        </div>
        <div class="v-col-3">
          <arora-nuxt-link
            class-name="v-main-menu-link v-footer-basket"
            :href="appConfig.VueSettingsPreRun.Links.CartFirstStep"
            :label="translate('footerPage.cart')"
            active-class="v-active"
          >
            <div class="v-bar--icon">
              <icon-menu-hand-bag />
              <cart-small-basket-counter />
            </div>
            <div class="v-bar--text">
              <span v-html="translate('footerPage.cart')" />
            </div>
          </arora-nuxt-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ExternalLink } from '~types/settingsVariants'

import { useWindowSize } from '@arora/common'

import { Guid } from '~api/consts'

const appConfig = useAppConfig()
const { translate } = useI18nSanitized()
const { isExtraSmall } = useWindowSize()

const menuStore = useMenuStore()

const externalLinksFooter = ref<ExternalLink[]>(
  (appConfig.VueSettingsPreRun.ExternalLinks as ExternalLink[])
    .filter((l) => l.Type === 'footer')
    .sort((a, b) => a.SortWeight - b.SortWeight)
)

const copyrightYears = computed<string>(() => {
  const startYear = appConfig.VueSettingsPreRun.CopyRightStartYear
  const currentYear = new Date().getFullYear()

  if (startYear === currentYear) {
    return `${translate('footerPage.copyright')} ${currentYear} ${appConfig.VueSettingsPreRun.CopyRightOrgName}`
  }
  return `${translate('footerPage.copyright')} ${startYear} - ${currentYear} ${appConfig.VueSettingsPreRun.CopyRightOrgName}`
})

const linkSiteAuthorRights = translate('footerPage.siteAuthorRights')

const linkSite = computed<string>(() => {
  if (
    linkSiteAuthorRights.includes('arora.pro') ||
    linkSiteAuthorRights.includes('foodtech.marketing') ||
    linkSiteAuthorRights.includes('mnogo.menu')
  ) {
    return linkSiteAuthorRights
  }
  return 'https://arora.pro'
})
</script>

<style lang="scss">
@use 'assets/variables';
@use 'assets/mixins';

.v-mobile-navigation {
  position: fixed;
  z-index: 1002;
  left: 0;
  right: 0;
  bottom: 0;
  height: calc(77px + env(safe-area-inset-bottom));
  padding-bottom: env(safe-area-inset-bottom);
  display: flex;
  justify-content: center;
  background: variables.$FooterBackgroundColor;
  box-shadow: variables.$FloatShadow;
  &:after {
    background: variables.$FooterColor;
    opacity: 0.1;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    height: 1px;
    position: absolute;
  }
  .v-main-menu-link {
    padding: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative;
    .v-bar {
      &--icon {
        max-width: 28px;
        max-height: 28px;
        margin-bottom: 8px;
        position: relative;

        svg {
          width: auto;
          height: 28px;
        }

        .v-lhun {
          opacity: 0.3;
          * {
            fill: variables.$FooterColor;
          }
          .v-fill-none {
            fill: none;
            stroke: variables.$FooterColor;
          }
        }
        .v-poros {
          * {
            stroke: variables.$FooterColor;
            opacity: 0.3;
          }
        }
        .v-badge-root {
          position: absolute;
          top: 0;
          right: -5px;
        }
      }

      &--text {
        color: variables.$FooterColor;
        opacity: 0.3;
      }
    }
    &.v-active {
      .v-bar {
        &--icon {
          * {
            stroke: variables.$PrimaryBackgroundColor;
            opacity: 1;
          }
          .v-lhun {
            * {
              fill: variables.$PrimaryBackgroundColor;
            }

            .v-fill-none {
              fill: none;
              stroke: variables.$PrimaryBackgroundColor;
            }
          }
        }
        &--text {
          color: variables.$PrimaryBackgroundColor;
          opacity: 1;
        }
      }
    }
  }
}

.v-copyright-holder {
  color: variables.$FooterColor;
  display: flex;
  justify-content: end;
  align-items: end;
  font-size: 1rem;
  font-weight: 500;
  gap: 4px;

  .v-logo-arora {
    max-width: 109px;
    min-height: 36px;
    &.v-default {
      path {
        fill: variables.$FooterColor;
      }
    }
  }
  @include mixins.sm {
    justify-content: start;
  }
}

.v-block-anim {
  margin-top: 20px;
  position: relative;
  overflow: hidden;
  .v-city-anim {
    background-size: contain;
    background-position-x: 0;
    width: 100%;
    min-width: 1920px;
    height: 200px;
    background-repeat: repeat-x;
    background-position-y: 0;
  }
}

.v-car-anim {
  max-width: 200px;
  animation: myAnim 10s linear 0s 1 normal forwards;
  animation-iteration-count: infinite;
  img {
    transform: translateY(-5px);
  }
}

@keyframes myAnim {
  0% {
    animation-timing-function: linear;
    transform: translateX(-500%);
  }

  100% {
    animation-timing-function: linear;
    transform: translateX(1920px);
  }
}

footer {
  padding: 28px 0;
  background: variables.$FooterBackgroundColor;
  color: variables.$FooterColor;

  @include mixins.sm {
    padding-bottom: calc(77px + env(safe-area-inset-bottom));
  }

  .v-icon-payment--item {
    svg {
      fill: variables.$FooterColor;
    }
  }
  hr {
    border-color: variables.$FooterColor;
    margin: 20px 0;
  }
  a,
  a:link,
  a:active,
  a:visited {
    color: variables.$FooterColor;
  }

  .v-operating-hours-delivery,
  .v-opening-hours-hall {
    opacity: 0.6;
  }
  .v-text-footer-about-rest {
    opacity: 0.4;
  }
  .v-apps {
    &--link {
      font-size: 0;
      flex: 0 0 calc(50% - 8px);
      max-width: calc(50% - 8px);
    }
  }
  .v-social-link-footer {
    .v-social-networks {
      flex-direction: row-reverse;

      .v-social-network-icon {
        color: variables.$FooterColorOpaq50;
      }
    }
  }
  .v-block-legal-professional {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
}
</style>
