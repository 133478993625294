import type { EmitterEvent } from '~types/common'

type emitterComposable = {
  eventEmit: (type: EmitterEvent) => void
  eventOn: (type: EmitterEvent, callback: () => void) => void
  eventOff: (type: EmitterEvent, callback: () => void) => void
}
export default function useEmitter(): emitterComposable {
  function eventEmit(type: EmitterEvent): void {
    if (import.meta.client && window.emitter) {
      window.emitter.emit(type)
    }
  }

  function eventOn(type: EmitterEvent, callback: () => void): void {
    if (import.meta.client && window.emitter) {
      window.emitter.on(type, callback)
    }
  }

  function eventOff(type: EmitterEvent, callback: () => void): void {
    if (import.meta.client && window.emitter) {
      window.emitter.off(type, callback)
    }
  }

  return {
    eventEmit,
    eventOff,
    eventOn
  }
}
