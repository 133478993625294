<i18n>
ru:
  forbidden: У вас нет доступа к этой странице
  forbiddenLeft: '4'
  forbiddenRight: '3'
  notFound: Страница не найдена...
  notFoundLeft: '4'
  notFoundRight: '4'
  internal: 'Извините, что-то пошло не так...'
  internalLeft: '5'
  internalRight: '0'
  backLink: На главную
ua:
  forbidden: У вас немає доступу до цієї сторінки
  forbiddenLeft: '4'
  forbiddenRight: '3'
  notFound: Сторінка не знайдена...
  notFoundLeft: '4'
  notFoundRight: '4'
  internal: 'Вибачте, щось пішло не так...'
  internalLeft: '5'
  internalRight: '0'
  backLink: На головну
us:
  forbidden: You do not have access to this page
  forbiddenLeft: '4'
  forbiddenRight: '3'
  notFound: Page not found...
  notFoundLeft: '4'
  notFoundRight: '4'
  internal: 'Sorry, something went wrong...'
  internalLeft: '5'
  internalRight: '0'
  backLink: To main
</i18n>

<template>
  <div
    class="v-error-page-container v-d-flex v-flex-column v-justify-content-center v-align-items-center"
  >
    <div
      v-if="errorCode === ErrorPageCodes.Forbidden"
      class="v-error-page-code-container"
    >
      <div class="v-error-page-code">
        <span v-html="translate('error.forbiddenLeft')" />
        <span class="v-error-page-dish">
          <arora-nuxt-image
            :alt="translate('error.forbidden')"
            :image="appConfig.VueSettingsPreRun.ErrorsPageImage"
            image-type="ProductSmall"
          />
        </span>
        <span v-html="translate('error.forbiddenRight')" />
      </div>
      <span v-html="translate('error.forbidden')" />
    </div>
    <div
      v-if="errorCode === ErrorPageCodes.NotFound"
      class="v-error-page-code-container"
    >
      <div class="v-error-page-code">
        <span v-html="translate('error.notFoundLeft')" />
        <span class="v-error-page-dish">
          <arora-nuxt-image
            :alt="translate('error.notFound')"
            :image="appConfig.VueSettingsPreRun.ErrorsPageImage"
            image-type="ProductSmall"
          />
        </span>
        <span v-html="translate('error.notFoundRight')" />
      </div>
      <span v-html="translate('error.notFound')" />
    </div>
    <div
      v-if="errorCode === ErrorPageCodes.Internal"
      class="v-error-page-code-container"
    >
      <div class="v-error-page-code">
        <span v-html="translate('error.internalLeft')" />
        <span class="v-error-page-dish">
          <arora-nuxt-image
            :alt="translate('error.internal')"
            :image="appConfig.VueSettingsPreRun.ErrorsPageImage"
            image-type="ProductSmall"
          />
        </span>
        <span v-html="translate('error.internalRight')" />
      </div>
      <span v-html="translate('error.internal')" />
    </div>
    <arora-button
      class-name="v-btn v-btn-primary v-error-page-link v-py-sm v-px-lg"
      :label="translate('error.backLink')"
      @click="backClick"
    >
      <span v-html="translate('error.backLink')" />
    </arora-button>

    
  </div>
</template>

<script setup lang="ts">
import { ErrorPageCodes } from '@arora/common'

const { errorCode, exceptionString } = defineProps<{
  errorCode: ErrorPageCodes
  exceptionString?: string | undefined
}>()

const { sanitize, translate } = useI18nSanitized()

const appConfig = useAppConfig()

function backClick(): void {
  clearError({ redirect: appConfig.VueSettingsPreRun.Links.MainLink })
}

onMounted(() => {
  if (exceptionString && import.meta.client) {
    console.groupCollapsed(
      `%c${errorCode} server exception`,
      'color: #E9624F; font-size: 18px; padding: 9px'
    )
    console.error(exceptionString)
    console.groupEnd()
  }
})
onUnmounted(() => reloadNuxtApp())
</script>

<style lang="scss">
@use 'assets/variables';
@use 'assets/mixins';

.v-error-page {
  &-container {
    height: 100vh;
    gap: 60px;
    background: variables.$BodyBackground;
  }

  &-code-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 35px;
    color: variables.$BodyTextColorLight;
    font-size: 1.3rem;
  }

  &-code {
    user-select: none;
    color: variables.$PrimaryBackgroundColor;
    font-size: 25rem;
    line-height: 25rem;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;

    @include mixins.md {
      font-size: 15rem;
      line-height: 15rem;
    }
  }

  &-dish {
    position: relative;
    margin: 0 -2.5rem;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    min-width: 250px;
    max-width: 290px;
    flex-grow: 1;

    @include mixins.md {
      margin: 0 -1.5rem;
      max-width: 190px;
    }
  }
}

a.v-error-page-link {
  font-size: 1.1rem;
}

.v-exception-details {
  max-height: 185px;
  font-size: 0.95rem;
  overflow: hidden;
  overflow-y: auto;
}
</style>
