<i18n>
ru:
  open: Раскрыть боковое меню
ua:
  open: Розкрити бічне меню
us:
  open: Expand side menu
</i18n>

<template>
  <header
    id="headerSmall"
    itemscope
    itemtype="http://schema.org/WPHeader"
  >
    <lazy-header-parts-mobile-app-invitation />
    <div
      v-if="addressSelectEnabled"
      class="v-mobile-header v-header"
    >
      <address-selector
        compact
        :external-links-contacts="externalLinksContacts"
      />
    </div>
    <div
      v-else-if="!appConfig.VueSettingsPreRun.HeaderShowAddressCitySelectInBurger"
      class="v-mobile-header v-header"
    >
      <lazy-address-city-selector
        compact
        :external-links-contacts="externalLinksContacts"
        :show-phones="false"
      />
    </div>
    <div class="v-mobile-header v-header v-d-flex v-align-items-center v-justify-content-between">
      <arora-button
        class-name="v-btn v-btn-link v-btn-text v-no-underline v-mobile-header--burger"
        ignore-settings
        :label="translate('headerSmall.open')"
        @click="() => toggleBurgerMenu(true)"
      >
        <icon-header-burger-menu class="v-mobile-header--burger" />
      </arora-button>
      <div class="v-mobile-header--image">
        <arora-nuxt-link
          itemscope
          :href="appConfig.VueSettingsPreRun.Links.MainLink"
          :label="translate('headerPage.homePage')"
          itemtype="https://schema.org/Brand"
        >
          <arora-nuxt-image
            preload
            :alt="translate('headerPage.homePage')"
            :image="appConfig.VueSettingsPreRun.HeaderLogo"
            itemprop="logo"
          />
        </arora-nuxt-link>
      </div>
      <div class="v-mobile-header--points__wrapper">
        <div
          v-if="appConfig.RestaurantSettingsPreRun.BonusSystemEnabled"
          class="v-mobile-header--points v-d-flex v-align-items-center v-pointer"
          @click="navigateToPoints"
        >
          <div class="v-mr-xs">
            <account-points-balance balance-only />
          </div>
          <icon-general-coins />
        </div>
      </div>
    </div>
  </header>
  <div
    :id="`${uid}-burger-backdrop`"
    class="v-slide-menu-backdrop"
    style="width: 0; height: 0; opacity: 0"
    @click="() => toggleBurgerMenu(false)"
  />
  <div
    :id="`${uid}-burger-menu`"
    class="v-burger-menu v-scrollbar"
    style="left: -270px"
  >
    <header-parts-additional-logos-block />
    <div
      class="v-burger-menu--logo-wrapper v-d-flex v-align-items-center v-justify-content-center"
      :class="{
        'v-burger-menu--logo-wrapper__use_footer': imageExists(appConfig.VueSettingsPreRun.FooterLogo)
      }"
    >
      <div class="v-burger-menu--logo">
        <arora-nuxt-image
          v-if="imageExists(appConfig.VueSettingsPreRun.FooterLogo)"
          :alt="translate('headerPage.homePage')"
          :image="appConfig.VueSettingsPreRun.FooterLogo"
        />
        <arora-nuxt-image
          v-else
          :alt="translate('headerPage.homePage')"
          :image="appConfig.VueSettingsPreRun.HeaderLogo"
        />
      </div>
    </div>
    <div class="v-burger-menu-elements v-d-flex v-flex-column">
      <div
        v-if="headerEasterEggAvailable"
        class="v-burger-menu-elements--easter-egg v-w-100 v-d-flex v-flex-row v-justify-content-start"
      >
        <header-parts-easter-egg />
      </div>
      <div class="v-burger-menu-elements--single">
        <address-selector
          v-if="addressSelectEnabled"
          :external-links-contacts="externalLinksContacts"
        />
        <lazy-address-city-selector
          v-else
          :compact="appConfig.VueSettingsPreRun.HeaderShowAddressCitySelectInBurger"
          :external-links-contacts="externalLinksContacts"
        />
      </div>
      <div
        v-if="
          appConfig.VueSettingsPreRun.HeaderEnableSocial && appConfig.SocialNetworksPreRun.length > 0
        "
        class="v-burger-menu-elements--single v-header-small-social"
      >
        <common-social-networks
          :enable-border="false"
          :text="translate('headerPage.titleSocialNetworksLinks')"
        />
      </div>
      <div
        v-if="appConfig.VueSettingsPreRun.HeaderLayout === 'Umbar' && externalLinksHeader.length > 0"
        class="v-py-sm v-burger-menu-elements--single"
      >
        <nav
          v-for="(link, index) in externalLinksHeader"
          :key="`v-links-header-${index}`"
          class="v-burger-menu--external-link v-mb-md"
        >
          <arora-nuxt-link
            :external-link="!link.Url.startsWith('/')"
            :href="link.Url"
            :label="clean(link.Name)"
            :open-in-new-tab="!link.Url.startsWith('/')"
            active-class="v-font-weight-600"
          />
        </nav>
      </div>
      <div
        v-else-if="
          appConfig.VueSettingsPreRun.HeaderLayout === 'Wold' && externalLinksContacts.length > 0
        "
        class="v-py-sm v-burger-menu-elements--single"
      >
        <div
          v-for="(contact, index) in externalLinksContacts"
          :key="`contact-mobile-${index}`"
          class="v-header-external-link v-mb-md"
        >
          <icon-address-mark class="v-address-mark-mobile v-mr-xs" />
          <div class="v-d-flex v-flex-column v-address-mark-link">
            <span
              class="v-mb-xs"
              v-html="sanitize(contact.Name)"
            />
            <arora-nuxt-link
              v-for="(phone, phoneIndex) in contact.Url.split(',')"
              :key="`header-wold-phone-${index}-${phoneIndex}`"
              class-name="v-header-external-link--contact v-pointer v-font-weight-600"
              external-link
              :href="`tel:+${phoneBeautified(sanitize(phone), 'header').replace(/\D+/g, '')}`"
              :label="phoneBeautified(sanitize(phone), 'header')"
            />
            <span
              v-if="!stringIsNullOrWhitespace(contact.Additional)"
              class="v-body-text-color-light"
              v-html="sanitize(contact.Additional)"
            />
          </div>
        </div>
      </div>
      <div class="v-py-sm">
        <common-apps />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ExternalLink } from '~types/settingsVariants'

import { useCommon } from '@arora/common'

import gsap from 'gsap'

defineProps<{
  headerEasterEggAvailable: boolean
  externalLinksHeader: ExternalLink[]
  externalLinksContacts: ExternalLink[]
}>()

const appConfig = useAppConfig()
const { stringIsNullOrWhitespace } = useCommon()
const { imageExists } = useImageInfo()
const { phoneBeautified } = usePhoneNumber()
const { sanitize, translate, clean } = useI18nSanitized()

const router = useRouter()

const uid = useId()

function toggleBurgerMenu(openMenu: boolean): void {
  if (openMenu) {
    document.body.classList.add('v-open-overflow')

    gsap.set(`#${uid}-burger-backdrop`, {
      height: '100vh',
      width: '100vw'
    })

    gsap.to(`#${uid}-burger-backdrop`, {
      duration: 0.4,
      ease: 'sine.inOut',
      opacity: 1
    })

    gsap.to(`#${uid}-burger-menu`, {
      delay: 0.1,
      duration: 0.3,
      ease: 'sine.inOut',
      left: 0
    })
  } else {
    document.body.classList.remove('v-open-overflow')

    gsap.set(`#${uid}-burger-backdrop`, {
      height: 0,
      width: 0
    })

    gsap.to(`#${uid}-burger-backdrop`, {
      delay: 0.1,
      duration: 0.3,
      ease: 'sine.inOut',
      opacity: 0
    })

    gsap.to(`#${uid}-burger-menu`, {
      duration: 0.4,
      ease: 'sine.inOut',
      left: -270
    })
  }
}

const addressSelectEnabled = computed<boolean>(
  () =>
    appConfig.RestaurantSettingsPreRun.OneStageCart &&
    (appConfig.RestaurantSettingsPreRun.AddressSelectOptions.ShowAddressSelectBeforeProductAdded ||
      appConfig.RestaurantSettingsPreRun.AddressSelectOptions.ShowAddressSelectAtEntrance)
)

function navigateToPoints(): void {
  navigateTo(appConfig.VueSettingsPreRun.Links.PointsLink)
}
router.beforeEach(() => {
  toggleBurgerMenu(false)
})
</script>

<style lang="scss">
@use 'assets/variables';
@use 'assets/mixins';

.v-address-mark-mobile {
  width: 1.1rem;
  height: 1.1rem;
}

#headerSmall {
  .v-address-mark-link {
    a,
    a:link,
    a:active,
    a:visited {
      color: variables.$BodyTextColor;
    }
  }
}
.v-header-small-social {
  .v-social-text-about {
    font-weight: 600;
  }

  .v-social-network-icon {
    width: 24px;
    height: 24px;
    color: variables.$SecondaryBackgroundColor;
    box-shadow: variables.$InputShadow;
  }
}

.v-mobile-header {
  padding: 0.8rem 0 0.9rem 1.1rem;
  width: 100%;
  overflow: hidden;

  &--image {
    width: 380px;
    padding-left: 1.75rem;
    padding-right: 1.75rem;

    img {
      max-height: 100px;
    }

    a {
      width: 100%;
      height: 100%;

      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &--burger {
    max-width: 24px;
    min-width: 24px;
    height: 24px;

    svg {
      width: 22px;
      height: 22px;

      path {
        stroke: variables.$HeaderColor;
      }
    }
  }

  &--points {
    border-radius: 50px 0 0 50px;
    background: variables.$SecondaryBackgroundColor;
    color: variables.$SecondaryColor;
    padding: 0.25rem 0.5rem;
    max-height: 2.5rem;

    &__wrapper {
      min-width: 40px;
    }

    svg {
      max-width: 22px;
      min-width: 22px;
      height: 22px;
    }
  }
}

.v-burger-menu {
  background: variables.$BodyElementsBackground;
  color: variables.$BodyTextColor;
  max-width: 270px;
  min-width: 270px;
  position: fixed;
  z-index: 1012;
  top: 0;
  height: 100vh;
  box-shadow: variables.$FloatShadow;

  overflow-y: auto;

  &--logo-wrapper {
    width: 100%;
    background: variables.$HeaderBackgroundColor;
    padding: 2rem;

    &__use_footer {
      background: variables.$FooterBackgroundColor;
    }
  }

  &--logo {
    height: auto;
    width: 210px;

    img {
      max-width: 200px;
      height: auto;
    }
  }

  &--external-link {
    font-size: 1.1rem;
  }

  .v-burger-menu-elements {
    padding: 0 1.1rem;

    &--easter-egg {
      padding: 1.25rem;

      svg {
        width: 60px;
        height: 60px;
      }
    }

    &--single {
      padding: 1.1rem;

      border-bottom: 1px solid rgba(125, 125, 125, 0.1);

      .v-current-rest {
        color: variables.$BodyTextColor;
      }

      .v-burger-menu--external-link {
        .v-link {
          color: variables.$BodyTextColor;
        }
      }
    }
  }
}

.v-header-external-link {
  display: -webkit-box;

  &--contact {
    margin-bottom: 1.25rem;

    @include mixins.sm {
      margin-bottom: 1rem;
    }
  }
}
</style>
