<template>
  <NuxtLink
    :active-class="activeClass"
    :aria-label="label.toString()"
    :class="`${className ?? 'v-link'} ${disabled ? 'disabled' : ''}`"
    :data-test-id="dataTestId"
    :disabled="disabled"
    :itemprop="itemprop"
    :itemscope="itemscope ? 'itemscope' : null"
    :itemtype="itemtype"
    :prefetch="prefetch"
    :rel="externalLink ? 'nofollow' : undefined"
    :target="openInNewTab ? '_blank' : '_self'"
    :to="hrefLocal"
  >
    <slot>{{ label }}</slot>
  </NuxtLink>
</template>

<script setup lang="ts">
import { type HasSchema, useCommon, type VElement } from '@arora/common'

const { externalLink, href, openInNewTab } = defineProps<
  VElement &
    HasSchema & {
      href: string
      activeClass?: string
      prefetch?: boolean
      openInNewTab?: boolean
      externalLink?: boolean
    }
>()

const { clean } = useI18nSanitized()

const appConfig = useAppConfig()
const { buildUTM } = useUrl()
const { stringIsNullOrWhitespace } = useCommon()

const hrefLocal = computed<string>(() => {
  if (!href) return '/'

  if (appConfig.RestaurantSettingsPreRun.DoNotSaveUTMOnRedirects || href.includes('?')) {
    return href
  }
  const utm = buildUTM()

  return clean(stringIsNullOrWhitespace(utm) ? href : `${href}${utm}`)
})
</script>

<style lang="scss">
@use 'assets/variables';

.v-link {
  font-size: 1rem;
  text-decoration: underline;
  color: variables.$LinkColor;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
  &.v-link--light {
    color: variables.$BodyTextColorLight;
  }
}
</style>
