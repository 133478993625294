<template>
  <Head>
    <Title>{{ error.message }}</Title>
    <Meta
      :content="error.message"
      name="og:title"
    />
    <Style
      :children="`:root { ${createGlobalTheme(appConfig.VueSettingsPreRun, false)} }`"
      type="text/css"
    />
  </Head>
  <header-page v-if="!route.meta.HideHeader" />
  <page-system-error
    :error-code="error.statusCode"
    :exception-string="error.stack ? `${error.message}${error.stack}` : error.message"
  />

  <footer-page v-if="!route.meta.HideFooter" />
</template>

<script setup lang="ts">
import type { NuxtError } from 'nuxt/app'

import createGlobalTheme from '~api/theme'

defineProps<{
  error: NuxtError
}>()

const route = useRoute()
const appConfig = useAppConfig()
</script>
