<template>
  <transition
    appear
    mode="out-in"
    name="slidedown"
  >
    <div
      v-if="
        appConfig.VueSettingsPreRun.HeaderEnableAdditionalLogos &&
        externalLinksAdditionalLogos.length > 0 &&
        !headerAdditionalLogos
      "
      class="v-header-additional-logos"
    >
      <div class="v-header-additional-logos-inside v-container">
        <div class="v-row">
          <div
            class="v-d-flex v-align-items-center"
            :class="[appConfig.VueSettingsPreRun.HeaderAdditionalLogosCanHide ? 'v-col-10' : 'v-col-12']"
          >
            <span
              class="v-header-additional-logos-inside--title v-mr-xxs"
              v-html="
                isDesktopVersion
                  ? translate('headerPage.additionalLogosBlockTitle')
                  : translate('headerPage.additionalLogosBlockTitleMobile')
              "
            />
            <div
              v-for="(additionalLogo, index) in externalLinksAdditionalLogos"
              :key="`add-logo-${index}`"
              class="v-header-additional-logos-inside--img v-pointer v-mr-xs"
            >
              <arora-nuxt-link
                open-in-new-tab
                :href="additionalLogo.Url"
                :label="additionalLogo.Url"
              >
                <arora-nuxt-image
                  :alt="additionalLogo.Url"
                  :height="40"
                  :image="additionalLogo.Image"
                  :width="40"
                />
              </arora-nuxt-link>
            </div>
          </div>
          <div
            v-if="appConfig.VueSettingsPreRun.HeaderAdditionalLogosCanHide"
            class="v-col-2 v-d-flex v-align-items-center v-justify-content-end"
          >
            <arora-button
              class-name="v-btn v-no-underline v-d-flex v-align-items-center v-header-additional-logos-inside--button v-btn-text"
              ignore-settings
              :label="translate('headerPage.additionalLogosBlockHide')"
              @click="close"
            >
              <span
                v-if="isDesktopVersion"
                class="v-header-additional-logos-inside--title v-mr-xxs"
                v-html="translate('headerPage.additionalLogosBlockHide')"
              />
              <icon-general-cross />
            </arora-button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script setup lang="ts">
import type { ExternalLink } from '~types/settingsVariants'

import { useWindowSize } from '@arora/common'

const { translate } = useI18nSanitized()
const headerAdditionalLogos = useCookie('headerAdditionalLogos')
const { isDesktopVersion } = useWindowSize()
const appConfig = useAppConfig()

function close(): void {
  headerAdditionalLogos.value = 'true'
}

const externalLinksAdditionalLogos = ref<ExternalLink[]>(
  (appConfig.VueSettingsPreRun.ExternalLinks as ExternalLink[])
    .filter((l) => l.Type === 'additionalLogo')
    .sort((a, b) => a.SortWeight - b.SortWeight)
)
</script>

<style lang="scss">
@use 'assets/variables';
@use 'assets/mixins';

.v-header-additional-logos {
  background: variables.$HeaderBackgroundColorContrast5;

  .v-header-additional-logos-inside {
    &--title {
      font-weight: 500;
      font-size: 1.1rem;
      padding: 20px 0;

      @include mixins.sm {
        font-size: 0.95rem;
        padding: 8px;
      }
    }

    &--img {
      max-height: 50px;
      max-width: 50px;
    }

    &--button {
      color: variables.$HeaderColor;
      opacity: 0.5;
      font-size: 0.9rem;

      svg {
        fill: variables.$HeaderColor;
      }

      &:hover {
        color: variables.$HeaderColor;
        opacity: 1;
      }
    }
  }
}
</style>
