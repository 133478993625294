const yaMapsScriptKey: Readonly<string> = 'ymaps3-script-id'

function makeScriptRecursive(
  apiKeys: string[],
  lang: string,
  resolve: (value: string | null) => void,
  reject: () => void,
  indexOfWorkingKey: number
): void {
  //add script to page
  const scriptYandexMap = document.createElement('script')

  scriptYandexMap.setAttribute('id', yaMapsScriptKey)
  scriptYandexMap.setAttribute(
    'src',
    `https://api-maps.yandex.ru/3.0/?apikey=${apiKeys[indexOfWorkingKey]}&lang=${lang}`
  )

  document.head.append(scriptYandexMap)

  scriptYandexMap.addEventListener('load', () => resolve(apiKeys[indexOfWorkingKey]))

  scriptYandexMap.onerror = () => {
    scriptYandexMap.remove()

    if (indexOfWorkingKey === apiKeys.length) reject()
    else makeScriptRecursive(apiKeys, lang, resolve, reject, indexOfWorkingKey + 1)
  }
}

export default {
  async loadYandexMap(apiKey: string): Promise<string | null> {
    return new Promise((resolve, reject) => {
      const scriptYandexMap = document.getElementById(yaMapsScriptKey)
      if (scriptYandexMap) {
        //got element by id but ymaps is not available yet
        scriptYandexMap.addEventListener('load', () => resolve(null))

        return
      }
      //convert document lang to Yandex Maps format
      let lang
      switch (document.documentElement.lang) {
        case 'uk': {
          //Ukrainian
          lang = 'uk_UA'
          break
        }
        case 'en': {
          //English
          lang = 'en_US'
          break
        }
        case 'tr': {
          //Turkish
          lang = 'tr_TR'
          break
        }
        default: {
          lang = 'ru_RU'
          break
        }
      }

      makeScriptRecursive(apiKey.split(','), lang, resolve, reject, 0)
    })
  }
}
