<i18n>
ru:
  count: 'Нет позиций | {n} позиция | {n} позиции | {n} позиций'
  fromPrice: от
ua:
  count: 'Нет позиций | {n} позиция | {n} позиции | {n} позиций'
  fromPrice: від
us:
  count: 'no items | one item | {count} items'
  fromPrice: from
</i18n>

<template>
  <div
    v-if="isHeader"
    class="v-w-100"
    v-sticky-top="{
      refresh: false,
      onRefresh: updateSticky
    }"
  >
    <menu-groups-barad-nimras v-if="appConfig.VueSettingsPreRun.MenuGroupsLayout === 'BaradNimras'" />
    <menu-groups-barrow-downs
      v-else-if="
        !(stickyLocal || !isDesktopVersion) &&
        appConfig.VueSettingsPreRun.MenuGroupsLayout === 'BarrowDowns'
      "
    />
    <menu-groups-barad-dur v-else-if="appConfig.VueSettingsPreRun.MenuGroupsLayout === 'BaradDur'" />
    <menu-groups-orthanc
      v-else-if="
        appConfig.VueSettingsPreRun.MenuGroupsLayout === 'Orthanc' ||
        ((stickyLocal || !isDesktopVersion) &&
          appConfig.VueSettingsPreRun.MenuGroupsLayout === 'BarrowDowns')
      "
    />
  </div>
  <template v-else-if="appConfig.VueSettingsPreRun.MenuMainGroupsShowImages">
    <menu-groups-main-page-isengard
      v-if="appConfig.VueSettingsPreRun.MenuMainGroupsLayout === 'Isengard'"
    />
    <menu-groups-main-page-helms-deep
      v-else-if="appConfig.VueSettingsPreRun.MenuMainGroupsLayout === 'HelmsDeep'"
    />
    <menu-groups-main-page-henneth-annun
      v-else-if="appConfig.VueSettingsPreRun.MenuMainGroupsLayout === 'HennethAnnun'"
    />
  </template>
</template>

<script setup lang="ts">
import { useWindowSize, vStickyTop } from '@arora/common'

defineProps<{
  isHeader?: boolean
}>()

const appConfig = useAppConfig()
const { isDesktopVersion } = useWindowSize()

const stickyLocal = ref<boolean>(false)
function updateSticky(isSticky: boolean): void {
  stickyLocal.value = isSticky
}
</script>
