import type { IMetric } from '~types/restaurantStore'

import { ExternalEvent } from '~api/consts'
import generalApi from '~api/general'

export default class GoogleMetric implements IMetric {
  sendMetricAddToCart(): Promise<void> {
    return Promise.resolve()
  }

  async sendMetricAfterOrder(): Promise<void> {
    return Promise.resolve()
  }

  async sendMetricBeforeOrder(): Promise<void> {
    await generalApi.externalEvent(
      new Map<string, number | string>([['externalEvent', ExternalEvent.GABeginCheckout]])
    )
  }

  async sendMetricCartView(isSmallCart: boolean): Promise<void> {
    const eventType = isSmallCart ? ExternalEvent.GASmallBasketView : ExternalEvent.GACartView

    await generalApi.externalEvent(new Map<string, number | string>([['externalEvent', eventType]]))
  }

  async sendMetricGroupView(): Promise<void> {
    return Promise.resolve()
  }

  sendMetricRemoveFromCart(): Promise<void> {
    return Promise.resolve()
  }

  SendMetricsProductView(): Promise<void> {
    return Promise.resolve()
  }
}
