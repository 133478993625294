<i18n>
ru:
  actionsBack: к списку акций
  actionsList: Акции
  actionSingle: Акция
  articlesBack: к списку статей
  articlesList: Акції
  menu: Меню сайта
  menuBack: в меню
  mainPage: Главная
  restaurantsBack: к разделу «Рестораны»
ua:
  actionsBack: до списку акцій
  actionsList: Акції
  actionSingle: Акція
  articlesBack: до списку статей
  articlesList: Стаття
  menu: Меню сайту
  menuBack: в меню
  mainPage: Головна
  restaurantsBack: до розділу «Ресторани»
us:
  actionsBack: back to promotions
  actionsList: Promotions
  actionSingle: Promotion
  articlesBack: back to articles
  articlesList: Articles
  menu: Menu
  menuBack: to the menu
  mainPage: Main page
  restaurantsBack: to the «Restaurants» section

</i18n>

<template>
  <div
    class="v-container v-py-sm v-mb-xs"
    v-show="appConfig.VueSettingsPreRun.BreadcrumbsShown && clientStore.Breadcrumbs.length > 1"
  >
    <ol
      class="v-d-flex v-flex-row v-align-items-end v-breadcrumb-wrapper"
      itemscope
      itemtype="https://schema.org/BreadcrumbList"
    >
      <li
        v-for="(breadcrumb, index) in clientStore.Breadcrumbs"
        :key="`breadcrumb-${index}-${breadcrumb.Link?.replaceAll('/', '-')}`"
        class="v-d-flex v-align-items-center"
        itemscope
        itemprop="itemListElement"
        itemtype="https://schema.org/ListItem"
      >
        <arora-nuxt-link
          class-name="v-breadcrumb"
          :label="
            stringIsNullOrWhitespace(breadcrumb.Name)
              ? sanitize(breadcrumb.MetaName)
              : translate(breadcrumb.Name)
          "
          :href="breadcrumb.Link"
          active-class="v-font-weight-600 v-pointer-events-none"
          itemprop="item"
        >
          <span
            itemprop="name"
            v-html="
              stringIsNullOrWhitespace(breadcrumb.Name)
                ? sanitize(breadcrumb.MetaName)
                : translate(breadcrumb.Name)
            "
          />
        </arora-nuxt-link>
        <meta
          :content="`${index + 1}`"
          itemprop="position"
        />
        <icon-general-chevron-right
          v-if="index < clientStore.Breadcrumbs.length - 1"
          class="v-mr-xs v-ml-xs v-breadcrumbs-icon"
        />
      </li>
    </ol>
  </div>
  <transition>
    <div
      v-if="clientStore.BreadcrumbBackLink"
      class="v-container v-mb-xs"
    >
      <div class="v-breadcrumb-back-link">
        <arora-nuxt-link
          class="v-link--light v-d-flex v-align-items-center"
          :href="clientStore.BreadcrumbBackLink"
          :label="clientStore.BreadcrumbBackText"
        >
          <icon-general-arrow-previous class="v-breadcrumbs-icon v-mr-xxs" />
          <span v-html="translate(clientStore.BreadcrumbBackText)" />
        </arora-nuxt-link>
      </div>
    </div>
  </transition>
</template>

<script setup lang="ts">
import { useCommon } from '@arora/common'

defineProps<{
  productName?: string | undefined
}>()
const appConfig = useAppConfig()

const { sanitize, translate } = useI18nSanitized()

const { stringIsNullOrWhitespace } = useCommon()
const clientStore = useClientStore()
</script>

<style lang="scss">
@use 'assets/variables';

.v-breadcrumb-wrapper {
  list-style-type: none;
  margin-block-start: 0;
  margin-block-end: 0;
  padding-inline-start: 0;

  a,
  a:link,
  a:active,
  a:visited {
    color: variables.$BodyTextColorLight;
  }

  .v-breadcrumbs-icon {
    width: 0.65rem;
    height: 0.65rem;
    fill: none;
    color: variables.$BodyTextColorLight;
  }
}
.v-breadcrumb-back-link {
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0.5px;
  gap: 4px;

  .v-breadcrumbs-icon {
    width: 0.85rem;
    height: 0.85rem;
    fill: none;
    color: variables.$BodyTextColorLight;
  }
}
</style>
