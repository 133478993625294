export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.config.errorHandler = (error) => {
    if (error && error.toString().includes('Failed to fetch dynamically imported module')) {
      window.location.reload()
    } else {
      window.YandexRotorSettings.IsError = true
      const { showInternalError } = usePopupStore()

      showInternalError(error as Error)
      console.error(error)
    }
  }
})
