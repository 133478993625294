import type {
  CityOrganisation,
  DeliveryAndPay,
  IAllTerminalsUnavailable,
  Restaurant,
  Street,
  Terminal,
  TerminalUnavailableMessage
} from '~types/addressStore'

import { type GUID, RequestMethod } from '@arora/common'

import { Guid } from '~api/consts'
import MakeRequest from '~api/request'

export default {
  async checkAllTerminals(): Promise<IAllTerminalsUnavailable> {
    return await MakeRequest<IAllTerminalsUnavailable>('/api/json/unavailableTerminalsMessage')
  },
  async checkTerminal(terminalId: GUID) {
    return await MakeRequest<TerminalUnavailableMessage>(
      '/api/unavailablemessage',
      new Map([['TerminalID', terminalId]])
    )
  },
  async getCities() {
    return await MakeRequest<CityOrganisation[]>(
      '/api/json/cities',
      new Map([['hideIfInactive', 'true']])
    )
  },
  async getDeliveryAndPay() {
    return await MakeRequest<DeliveryAndPay>('/api/json/deliveryAndPay')
  },
  async getRestaurants() {
    return await MakeRequest<Restaurant[]>('/api/json/restaurants')
  },
  async getStreetList(
    restaurantId: GUID,
    departmentId: GUID,
    inputString: string,
    cityId: GUID = Guid.Empty
  ) {
    const data = new FormData()

    data.append('restaurant', restaurantId)
    data.append('department', departmentId)
    data.append('city', cityId)
    data.append('input', inputString)

    return await MakeRequest<Street[]>('/ajax/streetList', null, RequestMethod.POST, data)
  },
  async getTerminals() {
    return await MakeRequest<Terminal[]>('/api/json/terminals')
  }
}
