import type { MapsSavedAddress, TwoStepsSavedAddress } from '~types/accountStore'
import type {
  CityOrganisation,
  districtDeliveryParameters,
  PriceRule,
  Restaurant,
  Terminal,
  terminalTemporarilyNoActive
} from '~types/addressStore'

import { type GUID, useCommon } from '@arora/common'

import { Guid, OrderDeliveryType, OrderType, RestaurantTypeMode } from '~api/consts'

type addressSelectorComposable = {
  getTerminalById: (terminalId: GUID) => Terminal
  districtDeliveryParametersByTerminal: () => districtDeliveryParameters
  minSumNotReached: ComputedRef<boolean>
  makeLink: (rest: Restaurant) => string
  makeLinkAsAway: (rest: Restaurant) => string
  cityName: ComputedRef<string | null>
  noAddress: ComputedRef<boolean>
  redirectToRestaurant: (rest: Restaurant, getParameters?: Map<string, number | string> | null) => void
  terminal: ComputedRef<Terminal | null>
  terminalTemporarilyNoActive: ComputedRef<terminalTemporarilyNoActive>
  savedAddressString: (address: MapsSavedAddress | TwoStepsSavedAddress) => string
}
export default function useAddressSelector(): addressSelectorComposable {
  const addressStore = useAddressStore()
  const clientStore = useClientStore()
  const popupStore = usePopupStore()
  const { stringIsNullOrWhitespace } = useCommon()
  const { sanitize, translate } = useI18nSanitized()

  const appConfig = useAppConfig()

  function redirectToRestaurant(
    rest: Restaurant,
    getParameters: Map<string, number | string> | null = null
  ): void {
    const { stringIsNullOrWhitespace } = useCommon()

    const cartContent = clientStore.ClientState.data?.Cart?.Content ?? []
    let linkUrl = stringIsNullOrWhitespace(rest.ExternalLink)
      ? `http://${rest.Domain}?fromCityPopup=true`
      : (rest.ExternalLink ?? '')
    const getParametersConverted: string[] = []
    if (getParameters)
      for (const [key, value] of getParameters) {
        getParametersConverted.push(`${key}=${value}`)
      }
    linkUrl += getParametersConverted.length > 0 ? `&${getParametersConverted.join('&')}` : ''
    popupStore.showConfirm({
      message:
        cartContent.length > 0
          ? translate('addressSelectorPopup.cityChange')
          : translate('addressSelectorPopup.cityChangeEmptyCart', { city: rest.Name }),
      noFunction: () => {
        reloadNuxtApp()
      },
      noText: translate('addressSelectorPopup.cancel'),
      type: 'warning',
      yesFunction: () => {
        navigateTo(linkUrl, { external: true })
      },
      yesOrNo: true,
      yesText: translate('addressSelectorPopup.confirm')
    })
  }

  function getTerminalById(terminalId: GUID): Terminal {
    const terminalData: Terminal[] = addressStore.Terminals.data ?? []

    if (terminalId && terminalData.length > 0) {
      const terminal: Terminal | undefined = terminalData.find((terminal) => {
        return terminal.ID === terminalId
      })

      if (terminal) return terminal
    }

    return {
      ID: terminalId
    } as Terminal
  }

  const terminal = computed<Terminal | null>(() => {
    if (clientStore.ClientState.data?.OrderType === OrderType.Default) return null

    const terminalData: Terminal[] = addressStore.Terminals.data ?? []

    if (
      clientStore.selectedTerminalId &&
      !Guid.IsNullOrEmpty(clientStore.selectedTerminalId) &&
      terminalData.length > 0
    ) {
      return (
        terminalData.find((terminal) => {
          return terminal.ID === clientStore.selectedTerminalId
        }) ?? null
      )
    }

    return null
  })

  function districtDeliveryParametersByTerminal(): districtDeliveryParameters {
    const result = {
      DeliverySum: 0,
      DeliverySumPaid: 0,
      IsPriceRulesOn: false,
      PriceRules: [] as PriceRule[]
    }

    if (clientStore.ClientState.data?.OrderType === OrderType.CourierDelivery) {
      const cityOrganisations: CityOrganisation[] = addressStore.CityOrganisation?.data ?? []

      const currentCity = cityOrganisations.find((city) => {
        return city.ID === clientStore.ClientState.data?.SelectedDeliveryAddress?.CityID
      })

      if (!currentCity || !currentCity.DistrictsPrices) return result

      const district = currentCity.DistrictsPrices.find((district) => {
        return district.ID === clientStore.ClientState.data?.SelectedDeliveryAddress?.DistrictID
      })

      if (!district) return result

      result.IsPriceRulesOn = district.DeliverySum === 0 && district.DeliverySumPaid === 0
      result.PriceRules = district.PriceRules.sort((a, b) => {
        return a.SumFrom - b.SumFrom
      })
      result.DeliverySum = Math.max(district.DeliverySum, currentCity.DeliverySum)
      result.DeliverySumPaid = Math.max(district.DeliverySumPaid, currentCity.DeliverySumPaid)
    }

    return result
  }

  const noAddress = computed<boolean>(() => {
    if (
      clientStore.ClientState.data?.OrderType === OrderType.NoShipment ||
      clientStore.ClientState.data?.OrderType === OrderType.InHall
    ) {
      return !clientStore.selectedTerminalId || Guid.IsNullOrEmpty(clientStore.selectedTerminalId)
    }

    const address = clientStore.ClientState.data?.SelectedDeliveryAddress

    return (
      !address ||
      Guid.IsNullOrEmpty(address.TerminalID) ||
      (address.GeoCity?.split(', ').length <= 1 && !address.GeoDistrict && !address.Street) ||
      stringIsNullOrWhitespace(address.House)
    )
  })

  // Проверка на то, что терминал временно не доступен
  const terminalTemporarilyNoActive = computed<terminalTemporarilyNoActive>(() => {
    const result: terminalTemporarilyNoActive = {
      IsTemporarilyNonActive: false,
      IsTerminalNoActive: false,
      TemporarilyUnavailableForDelivery: false,
      TemporarilyUnavailableForSelfService: false
    }

    const terminalData: Terminal[] | null = addressStore.Terminals.data

    if (!Guid.IsNullOrEmpty(clientStore.selectedTerminalId) && terminalData) {
      const terminal: Terminal | undefined = terminalData.find((terminal) => {
        return terminal.ID === clientStore.selectedTerminalId
      })

      if (terminal) {
        result.IsTemporarilyNonActive = terminal.IsTemporarilyNonActive
        result.TemporarilyUnavailableForSelfService = terminal.TemporarilyUnavailableForSelfService
        result.TemporarilyUnavailableForDelivery = terminal.TemporarilyUnavailableForDelivery
      }
    }

    result.IsTerminalNoActive =
      result.IsTemporarilyNonActive ||
      (result.TemporarilyUnavailableForDelivery &&
        clientStore.ClientState.data?.OrderType === OrderType.CourierDelivery) ||
      (result.TemporarilyUnavailableForSelfService &&
        (clientStore.ClientState.data?.OrderType === OrderType.NoShipment ||
          clientStore.ClientState.data?.OrderType === OrderType.InHall))

    return result
  })

  const cityName = computed<string | null>(() => {
    const restaurants: Restaurant[] | null = addressStore.Restaurants.data
    if (!restaurants) return null

    const selected: Restaurant | undefined = Object.values(restaurants).find((rest: Restaurant) => {
      return rest.ID === appConfig.CurrentRestaurantId
    })

    if (selected) return selected.Name

    return null
  })

  const minSumNotReached = computed<boolean>(() => {
    return (
      (clientStore.ClientState.data?.DeliveryPaymentInfo?.ModifiedMinSum ?? 0) >
      (clientStore.ClientState.data?.FinalCalculatePriceConsider ?? 0)
    )
  })

  function makeLink(rest: Restaurant): string {
    if (rest.ExternalLink) return rest.ExternalLink

    if (rest.Domain) {
      switch (rest.Type) {
        case RestaurantTypeMode.Folder: {
          const domain = rest.FolderIsEmpty ? rest.Domain : `${rest.Domain}/${rest.Alias}`

          return `http://${domain}?fromCityPopup=true`
        }
        case RestaurantTypeMode.Domain:
          return `http://${rest.Domain}?fromCityPopup=true`
      }
    }

    return '#'
  }

  function makeLinkAsAway(rest: Restaurant): string {
    return `/away?url=${makeLink(rest)}&RestaurantID=${rest.ID}`
  }

  function savedAddressString(address: MapsSavedAddress | TwoStepsSavedAddress): string {
    const result: string[] = []

    result.push(
      sanitize(address.Street),
      `${translate('savedAddressesSelect.house')} ${sanitize(address.House)}`
    )
    if (address.Corpus)
      result.push(`${translate('savedAddressesSelect.corpus')} ${sanitize(address.Corpus)}`)
    if (address.Doorway)
      result.push(`${translate('savedAddressesSelect.doorway')} ${sanitize(address.Doorway)}`)
    if (address.Floor)
      result.push(`${translate('savedAddressesSelect.floor')} ${sanitize(address.Floor)}`)
    if ('Intercome' in address && address.Intercome)
      result.push(`${translate('savedAddressesSelect.intercom')} ${sanitize(address.Intercome)}`)
    if ('Intercom' in address && address.Intercom)
      result.push(`${translate('savedAddressesSelect.intercom')} ${sanitize(address.Intercom)}`)
    if (address.Apartment)
      result.push(
        `${translate(address.OrderDeliveryType === OrderDeliveryType.Org ? 'savedAddressesSelect.office' : 'savedAddressesSelect.apartment')} ${sanitize(address.Apartment)}`
      )

    return result.join(', ')
  }

  return {
    cityName,
    districtDeliveryParametersByTerminal: districtDeliveryParametersByTerminal,
    getTerminalById,
    makeLink,
    makeLinkAsAway,
    minSumNotReached,
    noAddress,
    redirectToRestaurant,
    savedAddressString,
    terminal,
    terminalTemporarilyNoActive
  }
}
