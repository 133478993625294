import type {
  Action,
  Contacts,
  FeedbackData,
  FeedbackPayload,
  FeedbackSubjects,
  Hall,
  PartnersOfferPayload,
  Proffer,
  Vacancy,
  VacancyPayload
} from '~types/pageStore'

import { type GUID, RequestMethod } from '@arora/common'

import MakeRequest from '~api/request'

export default {
  async loadActions(): Promise<Action[]> {
    return await MakeRequest<Action[]>(
      '/api/json/action',
      new Map([
        ['active', 'true'],
        ['activePeriodically', 'true'],
        ['filter', 'true']
      ])
    )
  },
  async loadContacts() {
    return await MakeRequest<Contacts>('/api/json/RestaurantContacts')
  },
  async loadFeedback(page: number, count: number): Promise<FeedbackData> {
    return await MakeRequest<FeedbackData>(
      '/api/json/feedbackV2',
      new Map([
        ['count', count],
        ['skip', count * page]
      ])
    )
  },
  async loadFeedbackSubjects(): Promise<FeedbackSubjects[]> {
    return await MakeRequest<FeedbackSubjects[]>('/api/json/feedbackssubjects')
  },
  async loadRestaurants(): Promise<Hall[]> {
    return await MakeRequest<Hall[]>('/api/json/hallsV2')
  },
  async loadTableReservation(): Promise<Proffer[]> {
    return await MakeRequest<Proffer[]>('/api/json/table-reservation')
  },
  async loadVacancies(): Promise<Vacancy[]> {
    return await MakeRequest<Vacancy[]>('/api/json/vacancies')
  },
  async sendFeedback(restaurantId: GUID, feedbackFields: FeedbackPayload) {
    const getParameters = new Map<string, string>()
    const formData: FormData = new FormData()

    if (feedbackFields.name) formData.append('name', feedbackFields.name)
    if (feedbackFields.phone) formData.append('phone', feedbackFields.phone)
    if (feedbackFields.email) formData.append('email', feedbackFields.email)

    formData.append('restaurant_id', restaurantId)
    if (feedbackFields.cityId) formData.append('city_id', feedbackFields.cityId)
    if (feedbackFields.departmentId) formData.append('department_id', feedbackFields.departmentId)
    if (feedbackFields.terminalId) formData.append('terminal_id', feedbackFields.terminalId)
    if (feedbackFields.terminalName) formData.append('terminal_name', feedbackFields.terminalName)
    if (feedbackFields.attachment) formData.append('attachments[]', feedbackFields.attachment)

    if (feedbackFields.subject) formData.append('subject', feedbackFields.subject.toString())
    if (feedbackFields.text) formData.append('text', feedbackFields.text)
    if (feedbackFields.rating) formData.append('rating', feedbackFields.rating.toString())
    if (feedbackFields.caption) formData.append('caption', feedbackFields.caption)

    formData.append('notify_type', 'feedback')

    if (feedbackFields.smartcaptcha)
      formData.append('y-smart-captcha-response', feedbackFields.smartcaptcha)

    if (feedbackFields.recaptcha) {
      formData.append('g-recaptcha-response', feedbackFields.recaptcha)
      if (feedbackFields.passedV3) {
        formData.append('g-recaptcha-response-v3', feedbackFields.recaptcha)
        getParameters.set('captchaV3', 'true')
      }
    }

    return await MakeRequest('/ajax/feedbacksendV2', getParameters, RequestMethod.POST, formData)
  },
  async sendPartners(restaurantId: GUID, partnersFields: PartnersOfferPayload) {
    const formData: FormData = new FormData()
    const getParameters = new Map<string, string>()

    formData.append('id', partnersFields.partnersId)
    if (partnersFields.name) formData.append('name', partnersFields.name)
    if (partnersFields.phone) formData.append('phone', partnersFields.phone)
    if (partnersFields.email) formData.append('email', partnersFields.email)
    if (partnersFields.about) formData.append('about', partnersFields.about)
    formData.append('restaurant_id', restaurantId)
    formData.append('notify_type', 'partners-offer-answer')

    if (partnersFields.attachment) formData.append('attachments[]', partnersFields.attachment)

    if (partnersFields.smartcaptcha)
      formData.append('y-smart-captcha-response', partnersFields.smartcaptcha)

    if (partnersFields.recaptcha) {
      formData.append('g-recaptcha-response', partnersFields.recaptcha)
      if (partnersFields.passedV3) {
        formData.append('g-recaptcha-response-v3', partnersFields.recaptcha)
        getParameters.set('captchaV3', 'true')
      }
    }

    return await MakeRequest(
      '/ajax/send/answer/partneroffer',
      getParameters,
      RequestMethod.POST,
      formData
    )
  },
  async sendTableReservation(restaurantId: GUID, tableReservationFields: VacancyPayload) {
    const formData: FormData = new FormData()
    const getParameters = new Map<string, string>()

    if (tableReservationFields.name) formData.append('name', tableReservationFields.name)
    if (tableReservationFields.phone) formData.append('phone', tableReservationFields.phone)
    if (tableReservationFields.wishfulTerminalName)
      formData.append('wishful_terminal_name', tableReservationFields.wishfulTerminalName)
    if (tableReservationFields.email) formData.append('email', tableReservationFields.email)

    formData.append('restaurant_id', restaurantId)
    formData.append('vacancy_id', tableReservationFields.vacancyId)

    if (tableReservationFields.attachment)
      formData.append('attachments[]', tableReservationFields.attachment)

    if (tableReservationFields.bday) formData.append('birthday', tableReservationFields.bday)
    if (tableReservationFields.citizenship)
      formData.append('citizenship', tableReservationFields.citizenship)
    if (tableReservationFields.about) formData.append('about', tableReservationFields.about)

    formData.append('notify_type', 'table-reservation-answer')

    if (tableReservationFields.smartcaptcha)
      formData.append('y-smart-captcha-response', tableReservationFields.smartcaptcha)

    if (tableReservationFields.recaptcha) {
      formData.append('g-recaptcha-response', tableReservationFields.recaptcha)
      if (tableReservationFields.passedV3) {
        formData.append('g-recaptcha-response-v3', tableReservationFields.recaptcha)
        getParameters.set('captchaV3', 'true')
      }
    }

    return await MakeRequest(
      '/ajax/send/answer/tableReservation',
      getParameters,
      RequestMethod.POST,
      formData
    )
  },
  async sendVacancy(restaurantId: GUID, vacancyFields: VacancyPayload) {
    const getParameters = new Map<string, string>()
    const formData: FormData = new FormData()

    if (vacancyFields.name) formData.append('name', vacancyFields.name)
    if (vacancyFields.phone) formData.append('phone', vacancyFields.phone)
    if (vacancyFields.wishfulTerminalName)
      formData.append('wishful_terminal_name', vacancyFields.wishfulTerminalName)
    if (vacancyFields.email) formData.append('email', vacancyFields.email)

    formData.append('restaurant_id', restaurantId)
    formData.append('vacancy_id', vacancyFields.vacancyId)
    if (vacancyFields.bday) formData.append('birthday', vacancyFields.bday)
    if (vacancyFields.citizenship) formData.append('citizenship', vacancyFields.citizenship)
    if (vacancyFields.about) formData.append('about', vacancyFields.about)
    formData.append('notify_type', 'vacancy-answer')

    if (vacancyFields.attachment) formData.append('attachments[]', vacancyFields.attachment)

    if (vacancyFields.smartcaptcha)
      formData.append('y-smart-captcha-response', vacancyFields.smartcaptcha)

    if (vacancyFields.recaptcha) {
      formData.append('g-recaptcha-response', vacancyFields.recaptcha)
      if (vacancyFields.passedV3) {
        formData.append('g-recaptcha-response-v3', vacancyFields.recaptcha)
        getParameters.set('captchaV3', 'true')
      }
    }

    return await MakeRequest('/ajax/send/answer/vacancy', getParameters, RequestMethod.POST, formData)
  }
}
