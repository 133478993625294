import type { CartItem } from '~types/clientStore'
import type { IMetric, RestaurantSettings } from '~types/restaurantStore'

import { type GUID } from '@arora/common'

import { ExternalEvent } from '~api/consts'
import generalApi from '~api/general'

const mindboxScriptKey = 'mindbox-script-id'

export default class MindboxMetric implements IMetric {
  mindbox: any

  constructor(data: RestaurantSettings) {
    //add script to page
    const scriptMindbox = document.createElement('script')

    scriptMindbox.setAttribute('id', mindboxScriptKey)
    scriptMindbox.setAttribute('src', `https://${data.MindboxDomainApi}/scripts/v1/tracker.js`)

    document.head.appendChild(scriptMindbox)

    scriptMindbox.addEventListener('load', () => {
      this.mindbox = window.mindbox || {}

      this.mindbox.queue = this.mindbox.queue || []
      this.mindbox('create', {
        endpointId: data.MindboxSiteEndpoint
      })

      setTimeout(() => {
        const clientStore = useClientStore()
        const mindboxDeviceUUID = useCookie('mindboxDeviceUUID')

        if (mindboxDeviceUUID.value) {
          clientStore.setStateSource(mindboxDeviceUUID.value)
        } else {
          console.error('Mindbox device UUID is not set!')
        }
      }, 400)
    })
  }

  async sendMetricAddToCart(
    _groupID: GUID,
    _productID: GUID,
    _name: string,
    _priceModified: number,
    _count: number,
    _optionID: GUID
  ): Promise<void> {
    return Promise.resolve(undefined)
  }

  async sendMetricAfterOrder(
    _orderId: GUID,
    _totalAmountToPay: number,

    _content: CartItem[],
    _promo: string | undefined
  ): Promise<void> {
    return undefined
  }

  sendMetricBeforeOrder(): Promise<void> {
    return Promise.resolve(undefined)
  }

  sendMetricCartView(): Promise<void> {
    return Promise.resolve(undefined)
  }

  async sendMetricGroupView(groupId: GUID): Promise<void> {
    await generalApi.externalEvent(
      new Map<string, number | string>([
        ['group', groupId],
        ['mindboxEventType', ExternalEvent.MBViewGroup]
      ])
    )
  }

  async sendMetricRemoveFromCart(
    _groupID: GUID,
    _productID: GUID,
    _name: string,
    _count: number
  ): Promise<void> {}

  async SendMetricsProductView(
    GroupID: GUID,
    ProductID: GUID,
    Name: string,
    PriceModified: number,
    Count: number,
    OptionID: GUID | undefined
  ): Promise<void> {
    const parameters = new Map<string, number | string>([
      ['count', Count],
      ['group', GroupID],
      ['mindboxEventType', ExternalEvent.MBViewProduct],
      ['name', Name],
      ['product', ProductID],
      ['productPrice', PriceModified.toString()]
    ])

    if (OptionID !== undefined) {
      parameters.set('option', OptionID)
    }

    await generalApi.externalEvent(parameters)
  }
}
