<template>
  <svg
    v-if="appConfig.VueSettingsPreRun.IconsLayout === 'Lhun'"
    class="v-lhun"
    fill="none"
    height="20"
    viewBox="0 0 16 20"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clip-rule="evenodd"
      d="M8.54023 19.4704C10.3371 17.6738 16 11.7171 16 8C16 5.87827 15.1571 3.84344 13.6569 2.34315C12.1566 0.842855 10.1217 0 8 0C5.87827 0 3.84344 0.842855 2.34314 2.34315C0.842854 3.84344 0 5.87827 0 8C0 11.7171 5.66287 17.6738 7.45977 19.4704C7.76043 19.771 8.23956 19.771 8.54023 19.4704ZM8 11C9.65685 11 11 9.65685 11 8C11 6.34315 9.65685 5 8 5C6.34315 5 5 6.34315 5 8C5 9.65685 6.34315 11 8 11Z"
      fill="#FF9F10"
      fill-rule="evenodd"
    />
  </svg>
  <svg
    v-else-if="appConfig.VueSettingsPreRun.IconsLayout === 'Poros'"
    class="v-poros"
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
  >
    <path
      d="M23.3346 11.6663C23.3346 18.6663 14.0013 25.6663 14.0013 25.6663C14.0013 25.6663 4.66797 18.6663 4.66797 11.6663C4.66797 9.19099 5.6513 6.81702 7.40164 5.06668C9.15198 3.31634 11.5259 2.33301 14.0013 2.33301C16.4767 2.33301 18.8506 3.31634 20.601 5.06668C22.3513 6.81702 23.3346 9.19099 23.3346 11.6663Z"
      stroke="#F4F4F4"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M14 15.167C15.933 15.167 17.5 13.6 17.5 11.667C17.5 9.734 15.933 8.16699 14 8.16699C12.067 8.16699 10.5 9.734 10.5 11.667C10.5 13.6 12.067 15.167 14 15.167Z"
      stroke="#F4F4F4"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script setup lang="ts">
const appConfig = useAppConfig()
</script>
