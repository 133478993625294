import type { ExtraProduct, ModifierCounts } from '~types/clientStore'
import type { StoreState } from '~types/common'
import type {
  DiscountInfo,
  GiftsMultiple,
  GiftsRanged,
  Group,
  ItemInList,
  MenuRelated,
  MenuStore,
  Option,
  Product,
  ProductInList,
  ProductSearchResult,
  SortProductsType
} from '~types/menuStore'

import { type GUID } from '@arora/common'

import { computed, ref } from 'vue'

import { defineStore } from 'pinia'
import clientStateAPI from '~api/clientState'
import { favoriteId, Guid } from '~api/consts'
import menuAPI from '~api/menu'

export const useMenuStore = defineStore('menuStore', (): MenuStore => {
  const stateCurrentGroupId = ref<GUID | null>(null)

  const restaurantStore = useRestaurantStore()
  const appConfig = useAppConfig()
  const DiscountTimers = ref<StoreState<DiscountInfo>>({
    data: null,
    error: null,
    state: null
  })
  const GiftsRanged = ref<StoreState<GiftsRanged>>({
    data: null,
    error: null,
    state: null
  })
  const GiftsMultiple = ref<StoreState<GiftsMultiple>>({
    data: null,
    error: null,
    state: null
  })
  const AccountPointsMenu = ref<StoreState<ProductInList[]>>({
    data: null,
    error: null,
    state: null
  })
  const AccountFavoritesMenu = ref<StoreState<ProductInList[]>>({
    data: null,
    error: null,
    state: null
  })
  const ExtraProducts = ref<StoreState<ExtraProduct[]>>({
    data: null,
    error: null,
    state: null
  })

  const StopListModifierIds = ref<Set<GUID>>(new Set<GUID>())
  const StopListOptionIds = ref<Set<GUID>>(new Set<GUID>())
  const StopListProductIds = ref<Set<GUID>>(new Set<GUID>())

  const NonActiveGroupIds = ref<Set<GUID>>(new Set<GUID>())
  const NonActiveModifierIds = ref<Set<GUID>>(new Set<GUID>())
  const NonActiveProductIds = ref<Set<GUID>>(new Set<GUID>())

  const BlockedGroupIds = ref<Set<GUID>>(new Set<GUID>())
  const BlockedModifierIds = ref<Set<GUID>>(new Set<GUID>())
  const BlockedProductIds = ref<Set<GUID>>(new Set<GUID>())

  const ActivityMapLoaded = ref<boolean>(false)
  const ActivityMapLoadedAt = ref<number>(0)
  const CurrentItemsInRow = ref<number | null>(null)
  const FilterStickers = ref<GUID[]>([])
  const FilterTags = ref<GUID[]>([])
  const ProductsContainingById = ref<Record<GUID, ProductInList[]>>({})
  const RecommendedByGroup = ref<Map<GUID, ProductInList[]>>(new Map())
  const RelatedByProductId = ref<Record<GUID, MenuRelated>>({})
  const Recommended = ref<Map<GUID, { Group: Group | undefined; Product: ProductInList | undefined }>>(
    new Map()
  )
  const SelectedModifiersPerProduct = ref<Map<GUID, ModifierCounts>>(new Map())
  const SelectedOptionsPerProduct = ref<Map<GUID, Option>>(new Map())
  const Sorting = ref<SortProductsType>('default')

  const disabledButtonIfRequiredModsNotSelected = ref<boolean>(false)

  const CurrentGroupId = computed<GUID>(() => stateCurrentGroupId.value ?? Guid.Empty)
  const CurrentSubgroupId = ref<GUID | null | undefined>()

  const CurrentGroup = computed<Group | undefined>(() =>
    CurrentGroupId.value === Guid.Empty
      ? undefined
      : appConfig.Groups?.find((g) => g.ID === CurrentGroupId.value)
  )

  const CurrentSubgroup = computed<Group | undefined>(() =>
    CurrentSubgroupId.value === Guid.Empty
      ? undefined
      : appConfig.Groups?.find(
          (g) => !Guid.IsNullOrEmpty(g.ParentGroupID) && g.ID === CurrentSubgroupId.value
        )
  )

  const GroupsForDisplay = computed<Group[]>(() =>
    (appConfig.GroupsMain.filter((group) => !NonActiveGroupIds.value.has(group.ID)) ?? []).sort(
      (a, b) => a.SortWeight - b.SortWeight
    )
  )

  function setGroupIdManually(groupId: GUID | null, subgroupId: GUID | null, isMenuPage: boolean): void {
    FilterTags.value = []
    FilterStickers.value = []
    Sorting.value = 'default'
    stateCurrentGroupId.value = groupId
    CurrentSubgroupId.value = subgroupId
    if (groupId !== null && isMenuPage) {
      restaurantStore.Metrics?.sendMetricGroupView(groupId)
    }
  }

  async function refreshActivityMap(): Promise<void> {
    try {
      if (Date.now() - ActivityMapLoadedAt.value < 10000) return // no often than 10 seconds refresh
      ActivityMapLoadedAt.value = Date.now()
      const activityMap = await menuAPI.getActivityMap()

      StopListModifierIds.value = new Set<GUID>(activityMap.StopListMap.ModifierIds)
      StopListOptionIds.value = new Set<GUID>(activityMap.StopListMap.OptionIds)
      StopListProductIds.value = new Set<GUID>(activityMap.StopListMap.ProductIds)

      NonActiveGroupIds.value = new Set<GUID>(activityMap.NonActiveMap.GroupIds)
      NonActiveModifierIds.value = new Set<GUID>(activityMap.NonActiveMap.ModifierIds)
      NonActiveProductIds.value = new Set<GUID>(activityMap.NonActiveMap.ProductIds)

      BlockedGroupIds.value = new Set<GUID>(activityMap.BlockedMap.GroupIds)
      BlockedModifierIds.value = new Set<GUID>(activityMap.BlockedMap.ModifierIds)
      BlockedProductIds.value = new Set<GUID>(activityMap.BlockedMap.ProductIds)

      setTimeout(() => {
        ActivityMapLoaded.value = true
      }, 100)
    } catch (error) {
      ActivityMapLoadedAt.value = 0
      console.error(error)
    }
  }

  function setSelectedOption(
    item: ProductInList | null | undefined,
    firstLevel: GUID,
    optionId: GUID
  ): void {
    if (!item) return

    const option = item.Options[firstLevel].find((option: Option) => {
      return option.ID === optionId
    })

    if (!option) return

    restaurantStore.Metrics?.SendMetricsProductView(
      item.GroupID,
      option.ID,
      option.Name,
      option.Price,
      1,
      optionId
    )

    SelectedOptionsPerProduct.value.set(item.ID, option)
  }

  async function initExtraProducts(): Promise<void> {
    if (ExtraProducts.value.state !== 'success' && ExtraProducts.value.state !== 'loading') {
      await loadExtraProducts()
    }
  }

  async function loadExtraProducts(): Promise<void> {
    /* todo: avoid concurrent requests. I think, the best way is to cancel old requests
            it will work in case of sequential changes in the client state

            We can't prevent request if state is 'loading' because it may be outdated at the moment
        */
    ExtraProducts.value.state = 'loading'

    try {
      ExtraProducts.value.data = await clientStateAPI.getExtraProducts()
      ExtraProducts.value.state = 'success'
    } catch (error) {
      ExtraProducts.value.error = error
      ExtraProducts.value.state = 'error'
    }
  }

  async function toggleFilterSticker(stickerId: GUID): Promise<void> {
    switch (appConfig.VueSettingsPreRun.MenuStickersBehavior) {
      case 'default': {
        if (FilterStickers.value.includes(stickerId)) {
          FilterStickers.value.splice(FilterStickers.value.indexOf(stickerId), 1)
        } else {
          FilterStickers.value.push(stickerId)
        }
        break
      }
      case 'exclusive': {
        FilterStickers.value = FilterStickers.value.includes(stickerId) ? [] : [stickerId]
        break
      }
    }
  }

  async function toggleFilterIngredients(tagId: GUID): Promise<void> {
    if (FilterTags.value.includes(tagId)) {
      FilterTags.value = FilterTags.value.filter((item) => {
        return item !== tagId
      })
    } else {
      FilterTags.value.push(tagId)
    }
  }

  async function clearFilterIngredients(): Promise<void> {
    FilterTags.value = []
  }

  async function initDiscountTimers(groupId: GUID | null = null): Promise<void> {
    if (DiscountTimers.value.state !== 'success' && DiscountTimers.value.state !== 'loading') {
      await loadDiscountTimers(groupId)
    }
  }

  async function loadDiscountTimers(groupId: GUID | null = null): Promise<void> {
    DiscountTimers.value.state = 'loading'
    DiscountTimers.value.data = null

    try {
      DiscountTimers.value.data = await menuAPI.getDiscountTimers(groupId)
      DiscountTimers.value.state = 'success'
    } catch (error) {
      DiscountTimers.value.error = error
      DiscountTimers.value.state = 'error'
    }
  }

  async function initGiftsRanged(): Promise<void> {
    if (GiftsRanged.value.state !== 'success' && GiftsRanged.value.state !== 'loading') {
      await loadGiftsRanged()
    }
  }

  async function loadGiftsRanged(): Promise<void> {
    GiftsRanged.value.state = 'loading'

    try {
      GiftsRanged.value.data = await menuAPI.getGiftsRanged()
      GiftsRanged.value.state = 'success'
    } catch (error) {
      GiftsRanged.value.error = error
      GiftsRanged.value.state = 'error'
    }
  }

  async function loadGiftsMultiple(): Promise<void> {
    GiftsMultiple.value.state = 'loading'
    try {
      await menuAPI
        .getGiftsMultiple()
        .then((multipleGifts) => (GiftsMultiple.value.data = multipleGifts))
      GiftsMultiple.value.state = 'success'
    } catch (error) {
      GiftsMultiple.value.error = error
      GiftsMultiple.value.state = 'error'
    }
  }
  function applyFiltersAndSorting(menuListItems: ItemInList[]): ItemInList[] {
    let items: ItemInList[]
    if (FilterTags.value.length === 0 && FilterStickers.value.length === 0) {
      items = [...menuListItems]
    } else {
      let hasSticker: GUID[] = []
      let hasIngredient: GUID[] = []

      if (FilterStickers.value.length === 0) {
        hasSticker = menuListItems
          .filter((item) => item.Product)
          .map((item) => item.Product?.ID ?? Guid.Empty)
      } else {
        for (const menuItem of menuListItems) {
          if (menuItem.Product) {
            const stickersIds = menuItem.Product.StickerTags.map((sticker) => sticker.ID)
            if (menuItem.Product.IsFavorite) stickersIds.push(favoriteId)

            if (FilterStickers.value.every((sticker) => stickersIds.includes(sticker))) {
              hasSticker.push(menuItem.Product.ID)
            }
          }
        }
      }

      if (FilterTags.value.length === 0) {
        hasIngredient = menuListItems
          .filter((item) => item.Product)
          .map((item) => item.Product?.ID ?? Guid.Empty)
      } else {
        for (const menuItem of menuListItems) {
          if (menuItem.Product) {
            const tagsIds = new Set(menuItem.Product.Tags.map((tag) => tag.ID))

            if (FilterTags.value.every((tag) => tagsIds.has(tag))) {
              hasIngredient.push(menuItem.Product.ID)
            }
          }
        }
      }

      items = menuListItems.filter(
        (item) =>
          item.Product && hasSticker.includes(item.Product.ID) && hasIngredient.includes(item.Product.ID)
      )
    }

    switch (Sorting.value) {
      case 'price-asc': {
        items.sort((a, b) => {
          if (a.Product && b.Product) {
            const aPrice =
              a.Product.PriceModified + (SelectedOptionsPerProduct.value.get(a.Product.ID)?.Price ?? 0)
            const bPrice =
              b.Product.PriceModified + (SelectedOptionsPerProduct.value.get(b.Product.ID)?.Price ?? 0)

            return aPrice > bPrice ? 1 : -1
          }

          return 0
        })
        break
      }
      case 'price-desc': {
        items.sort((a, b) => {
          if (a.Product && b.Product) {
            const aPrice =
              a.Product.PriceModified + (SelectedOptionsPerProduct.value.get(a.Product.ID)?.Price ?? 0)
            const bPrice =
              b.Product.PriceModified + (SelectedOptionsPerProduct.value.get(b.Product.ID)?.Price ?? 0)

            return aPrice < bPrice ? 1 : -1
          }

          return 0
        })
        break
      }
      case 'name-asc': {
        items.sort((a, b) => {
          if (a.Product && b.Product) {
            return a.Product.Name > b.Product.Name ? 1 : -1
          }

          return 0
        })
        break
      }
      case 'name-desc': {
        items.sort((a, b) => {
          if (a.Product && b.Product) {
            return a.Product.Name < b.Product.Name ? 1 : -1
          }

          return 0
        })
        break
      }
    }

    //for pre-render
    if (!ActivityMapLoaded.value) {
      return items.filter(
        (item) => !item.Product // if it's banner
      )
    }
    return items.filter(
      (item) =>
        // if it's banner
        !item.Product ||
        //product is not blocked, and we know that it's currently working
        (!BlockedProductIds.value.has(item.Product.ID) &&
          !NonActiveProductIds.value.has(item.Product.ID))
    )
  }

  async function getRecommendedByGroupId(): Promise<ProductInList[] | null> {
    return await getRecommendedInternal(CurrentGroupId.value)
  }

  async function getRecommended(): Promise<ProductInList[] | null> {
    const { isLinkContains } = useUrl()

    let groupId: GUID | undefined

    if (
      isLinkContains(appConfig.VueSettingsPreRun.Links.CartFirstStep) ||
      isLinkContains(appConfig.VueSettingsPreRun.Links.CartSecondStep)
    ) {
      //order page
      groupId = 'FFFFFFFF-FFFF-FFFF-FFFF-FFFFFFFFFFFF' as GUID
    }

    return await getRecommendedInternal(groupId)
  }

  async function getRecommendedInternal(groupId: GUID | undefined): Promise<ProductInList[] | null> {
    let result: ProductInList[] | undefined = RecommendedByGroup.value.get(groupId ?? Guid.Empty)

    if (!result) {
      try {
        result = await menuAPI.loadRecommended(groupId)

        RecommendedByGroup.value.set(groupId ?? Guid.Empty, result)
      } catch (error) {
        console.error('store request error', error)

        return null
      }
    }

    return result
  }

  async function getProductsContainingById(productId: GUID): Promise<ProductInList[] | null> {
    let result: ProductInList[] | null = ProductsContainingById.value[productId]

    if (!result) {
      try {
        result = await menuAPI.loadProductsContainingById(productId)
        ProductsContainingById.value[productId] = result

        await getRelated(productId)
      } catch (error) {
        console.error('store request error', error)

        return null
      }
    }

    return result
  }

  async function initAccountPointsMenu(): Promise<void> {
    if (AccountPointsMenu.value.state !== 'success' && AccountPointsMenu.value.state !== 'loading') {
      await loadAccountPointsMenu()
    }
  }

  async function loadAccountPointsMenu(): Promise<void> {
    AccountPointsMenu.value.state = 'loading'
    AccountPointsMenu.value.data = null

    try {
      AccountPointsMenu.value.data = await menuAPI.loadAccountPointsMenu()
      AccountPointsMenu.value.state = 'success'
    } catch (error) {
      AccountPointsMenu.value.error = error
      AccountPointsMenu.value.state = 'error'
    }
  }

  async function initAccountFavoritesMenu(): Promise<void> {
    if (
      AccountFavoritesMenu.value.state !== 'success' &&
      AccountFavoritesMenu.value.state !== 'loading'
    ) {
      await loadAccountFavoritesMenu()
    }
  }

  async function loadAccountFavoritesMenu(): Promise<void> {
    AccountFavoritesMenu.value.state = 'loading'
    AccountFavoritesMenu.value.data = null

    try {
      const result = await menuAPI.loadAccountFavoritesMenu()
      AccountFavoritesMenu.value.data = result
      AccountFavoritesMenu.value.state = 'success'
    } catch (error) {
      AccountFavoritesMenu.value.error = error
      AccountFavoritesMenu.value.state = 'error'
    }
  }

  async function refreshRelated(productId: GUID | undefined): Promise<MenuRelated | null> {
    const pid = productId ?? Guid.Empty
    delete RelatedByProductId.value[pid]

    return await getRelated(productId)
  }

  async function getRelated(productId: GUID | undefined): Promise<MenuRelated | null> {
    const pid = productId ?? Guid.Empty
    let result: MenuRelated | null = RelatedByProductId.value[pid]

    if (!result) {
      try {
        result = await menuAPI.loadRelated(productId)

        RelatedByProductId.value[pid] = result
      } catch (error) {
        console.error('store request error', error)

        return null
      }
    }

    return result
  }

  async function getProductsBySearchString(
    text: string,
    textAlt: string
  ): Promise<ProductSearchResult[]> {
    try {
      return await menuAPI.loadProductsBySearchString(
        encodeURIComponent(text.trim()),
        encodeURIComponent(textAlt.trim())
      )
    } catch (error) {
      console.error('store request error', error)

      return []
    }
  }

  async function updateRecommended(ids: GUID[]): Promise<void> {
    Recommended.value = new Map()

    for (const id of ids) {
      Recommended.value.set(id, {
        Group: appConfig.Groups.find((group) => group.ID === id),
        Product: appConfig.Products.find((product) => product.ID === id)
      })
    }
  }

  async function getProducts(productIds: GUID[]): Promise<Product[] | null> {
    const result: Product[] = []

    if (productIds.length > 0) {
      try {
        const newProds = await menuAPI.loadProducts(productIds)

        for (const newProduct of newProds) {
          result.push(newProduct)
        }
      } catch (error) {
        console.error(error)

        return null
      }
    }

    return result
  }

  return {
    AccountFavoritesMenu,
    AccountPointsMenu,
    ActivityMapLoaded,
    applyFiltersAndSorting,
    BlockedGroupIds,
    BlockedModifierIds,
    BlockedProductIds,
    clearFilterIngredients,
    CurrentGroup,
    CurrentGroupId,
    CurrentItemsInRow,
    CurrentSubgroup,
    CurrentSubgroupId,
    disabledButtonIfRequiredModsNotSelected,
    DiscountTimers,
    ExtraProducts,
    FilterStickers,
    FilterTags,
    getProducts,
    getProductsBySearchString,
    getProductsContainingById,
    getRecommended,
    getRecommendedByGroupId,
    getRelated,
    GiftsMultiple,
    GiftsRanged,
    GroupsForDisplay,
    initAccountFavoritesMenu,
    initAccountPointsMenu,
    initDiscountTimers,
    initExtraProducts,
    initGiftsRanged,
    loadAccountFavoritesMenu,
    loadDiscountTimers,
    loadExtraProducts,
    loadGiftsMultiple,
    loadGiftsRanged,
    NonActiveGroupIds,
    NonActiveModifierIds,
    NonActiveProductIds,
    Recommended,
    RecommendedByGroup,
    refreshActivityMap,
    refreshRelated,
    SelectedModifiersPerProduct,
    SelectedOptionsPerProduct,
    setGroupIdManually,
    setSelectedOption,
    Sorting,
    StopListModifierIds,
    StopListOptionIds,
    StopListProductIds,
    toggleFilterIngredients,
    toggleFilterSticker,
    updateRecommended
  }
})
