import type { StoreState } from '~types/common'

import type { GUID } from '@arora/common'

import { computed, ref } from 'vue'

import { defineStore } from 'pinia'
import addressAPI from '~api/address'
import {
  type AddressStore,
  AllTerminalsUnavailable,
  type CityOrganisation,
  type DeliveryAndPay,
  type DistrictsPrice,
  type Restaurant,
  type ShowCityPopupPayload,
  type Street,
  type Terminal,
  type TerminalUnavailableMessage
} from '~types/addressStore'

export const useAddressStore = defineStore('addressStore', (): AddressStore => {
  const CityOrganisation = ref<StoreState<CityOrganisation[]>>({
    data: null,
    error: null,
    state: null
  })
  const DeliveryAndPay = ref<StoreState<DeliveryAndPay>>({
    data: null,
    error: null,
    state: null
  })
  const Restaurants = ref<StoreState<Restaurant[]>>({
    data: null,
    error: null,
    state: null
  })
  const Terminals = ref<StoreState<Terminal[]>>({
    data: null,
    error: null,
    state: null
  })
  const StreetsList = ref<StoreState<Street[]>>({
    data: null,
    error: null,
    state: null
  })

  const ActiveCities = computed<CityOrganisation[]>(() =>
    (
      CityOrganisation.value.data?.filter((item: CityOrganisation) => {
        return item.Active
      }) ?? []
    ).sort((a, b) => {
      return a.Weight === undefined || a.Weight > b.Weight ? 1 : -1 //sort as in the back
    })
  )

  const DeliveryZones = ref<Map<string, DistrictsPrice>>(new Map<string, DistrictsPrice>())

  async function initCities(): Promise<void> {
    if (CityOrganisation.value.state !== 'success' && CityOrganisation.value.state !== 'loading') {
      await loadCities()
    }
  }

  async function loadCities(): Promise<void> {
    CityOrganisation.value.state = 'loading'

    try {
      CityOrganisation.value.data = await addressAPI.getCities()
      CityOrganisation.value.state = 'success'

      const deliveryZonesLocal = new Map<string, DistrictsPrice>()

      for (const city of ActiveCities.value) {
        for (const district of city.DistrictsPrices) {
          if (district.Active && !deliveryZonesLocal.has(district.PolygonConfiguration.FillColor)) {
            deliveryZonesLocal.set(district.PolygonConfiguration.FillColor, district)
          }
        }
      }

      DeliveryZones.value = deliveryZonesLocal
    } catch (error) {
      CityOrganisation.value.error = error
      CityOrganisation.value.state = 'error'
    }
  }

  async function initRestaurant(): Promise<void> {
    if (Restaurants.value.state !== 'success' && Restaurants.value.state !== 'loading') {
      await loadRestaurant()
    }
  }

  async function loadRestaurant(): Promise<void> {
    Restaurants.value.state = 'loading'

    try {
      Restaurants.value.data = await addressAPI.getRestaurants()
      Restaurants.value.state = 'success'
    } catch (error) {
      Restaurants.value.error = error
      Restaurants.value.state = 'error'
    }
  }

  async function initTerminals(): Promise<void> {
    if (Terminals.value.state !== 'success' && Terminals.value.state !== 'loading') {
      await loadTerminals()
    }
  }

  async function loadTerminals(): Promise<void> {
    Terminals.value.state = 'loading'

    try {
      Terminals.value.data = await addressAPI.getTerminals()
      Terminals.value.state = 'success'
    } catch (error) {
      Terminals.value.error = error
      Terminals.value.state = 'error'
    }
  }

  async function initDeliveryAndPay(): Promise<void> {
    if (DeliveryAndPay.value.state !== 'success' && DeliveryAndPay.value.state !== 'loading') {
      await loadDeliveryAndPay()
    }
  }

  async function loadDeliveryAndPay(): Promise<void> {
    DeliveryAndPay.value.state = 'loading'

    try {
      DeliveryAndPay.value.data = await addressAPI.getDeliveryAndPay()
      DeliveryAndPay.value.state = 'success'
    } catch (error) {
      DeliveryAndPay.value.error = error
      DeliveryAndPay.value.state = 'error'
    }
  }

  async function loadStreetList(
    departmentId: GUID,
    input: string,
    cityId: GUID | undefined
  ): Promise<void> {
    StreetsList.value.state = 'loading'

    try {
      const { CurrentRestaurantId } = useAppConfig()

      StreetsList.value.data = cityId
        ? await addressAPI.getStreetList(CurrentRestaurantId, departmentId, input, cityId)
        : await addressAPI.getStreetList(CurrentRestaurantId, departmentId, input)
      StreetsList.value.state = 'success'
    } catch (error) {
      StreetsList.value.error = error
      StreetsList.value.state = 'error'
    }
  }

  async function checkTerminal(terminalId: GUID | undefined): Promise<TerminalUnavailableMessage> {
    if (!terminalId) return { enableTerminal: false, unavailableMessage: '' }

    try {
      return await addressAPI.checkTerminal(terminalId)
    } catch (error) {
      console.error(
        error.code
          ? `Code ${error.code}: failed to check terminal - ${error.message ?? 'no message'}`
          : 'Failed to check terminal',
        error
      )
      throw error
    }
  }

  async function checkAllTerminals(): Promise<void> {
    const unavailableTerminalMessage = useCookie('unavailableTerminalMessage')

    if (!unavailableTerminalMessage.value) {
      try {
        const answer = new AllTerminalsUnavailable(await addressAPI.checkAllTerminals())

        if (answer.shouldShowMessage()) {
          const popupStore = usePopupStore()

          await popupStore.showWarning(answer.message)
          unavailableTerminalMessage.value = 'true'
        }
      } catch (error) {
        console.error(
          error.code
            ? `Code ${error.code}: failed to check all terminals - ${error.message ?? 'no message'}`
            : 'Failed to check all terminals',
          error
        )
        throw error
      }
    }
  }

  async function onProductAdd(noAddress: boolean): Promise<void> {
    const appConfig = useAppConfig()
    const addressSelectSeenBeforeProductAdded = useCookie('unavailableTerminalMessage')

    const popupWasNotShown =
      appConfig.RestaurantSettingsPreRun.AddressSelectOptions?.AddressMustBeSelectedBeforeProductAdded ||
      !addressSelectSeenBeforeProductAdded.value
    if (
      noAddress &&
      appConfig.RestaurantSettingsPreRun.AddressSelectOptions?.ShowAddressSelectBeforeProductAdded &&
      popupWasNotShown
    ) {
      const popupStore = usePopupStore()
      await popupStore.openPopup({
        onClosePopup: () => {
          if (
            !appConfig.RestaurantSettingsPreRun.AddressSelectOptions
              ?.AddressMustBeSelectedBeforeProductAdded
          )
            addressSelectSeenBeforeProductAdded.value = 'true'
        },
        popupClosable:
          !appConfig.RestaurantSettingsPreRun.AddressSelectOptions
            ?.AddressMustBeSelectedBeforeProductAdded,
        popupName: 'addressSelectorPopup',
        popupProperties: new Map<string, unknown>([
          ['onEntrance', false],
          ['onProductAdd', true]
        ])
      })
    }
  }

  async function showCityPopupAtEntrance(payload: ShowCityPopupPayload): Promise<void> {
    const citySelectSeenAtEntrance = useCookie('citySelectSeenAtEntrance')

    if (!citySelectSeenAtEntrance.value && payload.fromCityPopup) {
      citySelectSeenAtEntrance.value = 'true'
    }

    if (payload.showPopupOnce) {
      if (citySelectSeenAtEntrance.value) return

      citySelectSeenAtEntrance.value = 'true'
    }

    const popupStore = usePopupStore()
    await popupStore.openPopup({
      popupClosable: payload.showCloseButton,
      popupName: 'citySelectorPopup',
      popupProperties: new Map<string, unknown>([['listCity', payload.listCity]])
    })
  }

  async function showPopupAtEntrance(noAddress: boolean): Promise<void> {
    const appConfig = useAppConfig()
    const addressSelectSeenAtEntrance = useCookie('addressSelectSeenAtEntrance')

    const popupWasNotShown =
      appConfig.RestaurantSettingsPreRun.AddressSelectOptions?.AddressMustBeSelectedAtEntrance ||
      !addressSelectSeenAtEntrance.value

    if (
      noAddress &&
      appConfig.RestaurantSettingsPreRun.AddressSelectOptions?.ShowAddressSelectAtEntrance &&
      popupWasNotShown
    ) {
      const popupStore = usePopupStore()
      await popupStore.openPopup({
        onClosePopup: () => {
          if (!appConfig.RestaurantSettingsPreRun.AddressSelectOptions?.AddressMustBeSelectedAtEntrance)
            addressSelectSeenAtEntrance.value = 'true'
        },
        popupClosable:
          !appConfig.RestaurantSettingsPreRun.AddressSelectOptions?.AddressMustBeSelectedAtEntrance,
        popupName: 'addressSelectorPopup',
        popupProperties: new Map<string, unknown>([
          ['onEntrance', true],
          ['onProductAdd', false]
        ])
      })
    }
  }

  return {
    ActiveCities,
    checkAllTerminals,
    checkTerminal,
    CityOrganisation,
    DeliveryAndPay,
    DeliveryZones,
    initCities,
    initDeliveryAndPay,
    initRestaurant,
    initTerminals,
    loadStreetList,
    onProductAdd,
    Restaurants,
    showCityPopupAtEntrance,
    showPopupAtEntrance,
    StreetsList,
    Terminals
  }
})
