<template>
  <transition
    mode="out-in"
    name="fade"
  >
    <div
      v-if="subgroups && subgroups.length > 0"
      class="v-subgroups__dol-guldur v-py-xs"
      data-test-id="menu-subgroups-links-container"
    >
      <arora-chips
        with-links
        :chips="subgroups"
        data-test-id="menu-subgroups-links"
        theme="secondary"
        v-model:selected="selectedSubgroup"
      />
    </div>
  </transition>
</template>

<script setup lang="ts">
import type { Group } from '~types/menuStore'

import { type GUID } from '@arora/common'

import { Guid } from '~api/consts'

const { subgroups } = defineProps<{ subgroups: Group[] | null | undefined }>()

const route = useRoute()

const selectedSubgroup = computed<GUID>({
  get: () => (route.meta.SubgroupID ? (route.meta.SubgroupID as GUID) : Guid.Empty),
  set: (value) => {
    const foundGroup = subgroups!.find((g) => g.ID === value)

    if (foundGroup) navigateTo(foundGroup.Link)
  }
})
</script>

<style lang="scss">
@use 'assets/mixins';

.v-subgroups__dol-guldur {
  @include mixins.sm {
    margin-top: 1rem;
  }
}
</style>
