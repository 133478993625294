<template>
  <div
    v-if="appConfig.VueSettingsPreRun.ElementLoaderType === 'Eriador'"
    class="v-loader__Eriador"
  >
    <div class="v-loader-spinner">
      <div
        v-for="index in Array.from({ length: 12 }, (_v, i) => i)"
        :key="`loader-${index}`"
      />
    </div>
  </div>
  <div
    v-else
    class="v-loader__Lorien"
  >
    <svg
      class="v-spinner"
      height="65px"
      viewBox="0 0 66 66"
      width="65px"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        class="path"
        cx="33"
        cy="33"
        fill="none"
        r="30"
        stroke-linecap="round"
        stroke-width="6"
      />
    </svg>
  </div>
</template>

<script setup lang="ts">
const appConfig = useAppConfig()
</script>

<style lang="scss">
@use 'assets/variables';

.v-loader__Eriador {
  text-align: center;

  .v-loader-spinner {
    color: variables.$PrimaryBackgroundColor;
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }

  .v-loader-spinner div {
    transform-origin: 40px 40px;
    animation: v-loader-spinner 1.2s linear infinite;
  }

  .v-loader-spinner div:after {
    content: ' ';
    display: block;
    position: absolute;
    top: 3px;
    left: 37px;
    width: 6px;
    height: 18px;
    border-radius: 20%;
    background: variables.$PrimaryBackgroundColor;
  }

  .v-loader-spinner div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;
  }

  .v-loader-spinner div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -1s;
  }

  .v-loader-spinner div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.9s;
  }

  .v-loader-spinner div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.8s;
  }

  .v-loader-spinner div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.7s;
  }

  .v-loader-spinner div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.6s;
  }

  .v-loader-spinner div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.5s;
  }

  .v-loader-spinner div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.4s;
  }

  .v-loader-spinner div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.3s;
  }

  .v-loader-spinner div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.2s;
  }

  .v-loader-spinner div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.1s;
  }

  .v-loader-spinner div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
  }
}

$offset: 187;
$duration: 1.4s;

.v-loader__Lorien {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  .v-spinner {
    height: 35%;
    width: 35%;
    animation: rotator $duration linear infinite;
  }

  .path {
    stroke-dasharray: $offset;
    stroke-dashoffset: 0;
    transform-origin: center;
    animation:
      dash $duration ease-in-out infinite,
      colors ($duration * 4) ease-in-out infinite;
  }
}

@keyframes rotator {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(270deg);
  }
}

@keyframes dash {
  0% {
    stroke-dashoffset: $offset;
  }
  50% {
    stroke-dashoffset: calc($offset / 4);
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: $offset;
    transform: rotate(450deg);
  }
}

@keyframes v-loader-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>
