// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import sbjs from 'sourcebuster'

type appsLinksComposable = {
  appStoreLink: ComputedRef<string | null>
  appsLength: ComputedRef<number>
  androidStoresLink: ComputedRef<string | null>
  ruStoreLink: ComputedRef<string | null>
  googlePlayLinkWithUTM: ComputedRef<string | null>
  huaweiAppGalleryLinkWithUTM: ComputedRef<string | null>
  hasAnyAndroidStoreEnabled: ComputedRef<boolean>
}
export default function useAppsLinks(): appsLinksComposable {
  const appConfig = useAppConfig()

  function buildUTMFromSourceBooster(): string {
    const result: string[] = []

    if (import.meta.client) {
      result.push(
        `utm_source=${sbjs?.get?.current?.src}`,
        `utm_medium=${sbjs?.get?.current?.mdm}`,
        `utm_campaign=${sbjs?.get?.current?.cmp}`,
        `utm_term=${sbjs?.get?.current?.trm}`
      )

      const ga = useCookie('_ga')

      result.push(`utm_content=${ga.value ? ga.value.replaceAll(/GA1.[0-9]./g, '') : '(none)'}`)
    }
    return result.join('&')
  }

  const appStoreLink = computed<string | null>(() =>
    appConfig.AppsPreRun.AppStoreEnabled ? appConfig.AppsPreRun.AppStoreLink : null
  )

  const androidStoresLink = computed<string | null>(() => {
    if (!appConfig.AppsPreRun.AppGooglePlayIdEnabled || !appConfig.AppsPreRun.AppGooglePlayId)
      return null

    return `market://details?id=${appConfig.AppsPreRun.AppGooglePlayId}&referrer=${encodeURIComponent(buildUTMFromSourceBooster())}`
  })

  const googlePlayLinkWithUTM = computed<string | null>(() => {
    if (!appConfig.AppsPreRun.GooglePlayEnabled || !appConfig.AppsPreRun.GooglePlayLink) return null

    return `${appConfig.AppsPreRun.GooglePlayLink}${appConfig.AppsPreRun.GooglePlayLink.includes('?') ? '&' : '?'}referrer=${encodeURIComponent(buildUTMFromSourceBooster())}`
  })

  const ruStoreLink = computed<string | null>(() =>
    appConfig.AppsPreRun.RuStoreLinkEnabled ? appConfig.AppsPreRun.RuStoreLink : null
  )

  const appsLength = computed<number>(() => {
    let result = 0

    if (appConfig.AppsPreRun.AppStoreEnabled) result++
    if (appConfig.AppsPreRun.HuaweiAppGalleryEnabled) result++
    if (appConfig.AppsPreRun.RuStoreLinkEnabled) result++
    if (appConfig.AppsPreRun.GooglePlayEnabled) result++

    return result
  })

  const hasAnyAndroidStoreEnabled = computed<boolean>(
    () =>
      appConfig.AppsPreRun.AppGooglePlayIdEnabled ||
      appConfig.AppsPreRun.GooglePlayEnabled ||
      appConfig.AppsPreRun.HuaweiAppGalleryEnabled ||
      appConfig.AppsPreRun.RuStoreLinkEnabled
  )

  const huaweiAppGalleryLinkWithUTM = computed<string | null>(() => {
    if (!appConfig.AppsPreRun.HuaweiAppGalleryEnabled || !appConfig.AppsPreRun.HuaweiAppGalleryLink)
      return null

    return `${appConfig.AppsPreRun.HuaweiAppGalleryLink}${appConfig.AppsPreRun.HuaweiAppGalleryLink.includes('?') ? '&' : '?'}referrer=${encodeURIComponent(buildUTMFromSourceBooster())}`
  })

  return {
    androidStoresLink,
    appsLength,
    appStoreLink,
    googlePlayLinkWithUTM,
    hasAnyAndroidStoreEnabled,
    huaweiAppGalleryLinkWithUTM,
    ruStoreLink
  }
}
