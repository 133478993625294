<template>
  <section class="v-groups-main__HelmsDeep">
    <common-cards-flex-mesh
      v-if="isNotUltraSmall"
      class-name="v-group-helms-deep-card"
      :items="menuStore.GroupsForDisplay"
      :max-items="appConfig.VueSettingsPreRun.MenuMainGroupsInRow"
      :min-items="2"
    >
      <template #item="item: Group">
        <menu-groups-main-page-helms-deep-card :group="item" />
      </template>
    </common-cards-flex-mesh>
    <common-cards-flex-mesh
      v-else
      class-name="v-group-helms-deep-card"
      :items="menuStore.GroupsForDisplay"
      :max-items="1"
      :min-items="1"
    >
      <template #item="item: Group">
        <menu-groups-main-page-helms-deep-card :group="item" />
      </template>
    </common-cards-flex-mesh>
  </section>
</template>

<script setup lang="ts">
import type { Group } from '~types/menuStore'

import { useWindowSize } from '@arora/common'

const menuStore = useMenuStore()

const appConfig = useAppConfig()
const { isNotUltraSmall } = useWindowSize()
</script>

<style lang="scss">
@use 'assets/variables';
@use 'assets/mixins';

.v-groups-main__HelmsDeep {
  font-family: variables.$FontFamilyMenuGroups;
}
</style>
